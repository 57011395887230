/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Button, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import './HEInput.scss';
import axios from 'axios';

const ROUND3 = (x) => Math.round(1000*x)/1000.;
const COP47F_TO_HSPF = (cop) => ROUND3((cop-1.108)/0.282);
const HSPF_TO_COP47F = (hspf) => ROUND3((hspf*0.282)+1.108);

class HEInput extends React.Component {

    static propTypes = {
        onChange: PropTypes.func,
        valueCOP: PropTypes.number,
        valueHSPF: PropTypes.number,
        extraClass: PropTypes.string
    };

    static defaultProps = {
        onChange: (valueCOP, valueHSPF) => { },
        valueCOP: null,
        valueHSPF: null,
        extraClass: ''
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            mode: (window.TEMP_UNIT_HEATING_EFF ?? this.props.currentUser.unitHeatingEff) ?? 'cop'
        };

        if (this.state.mode === 'cop') {
            this.state.value = this.props.valueCOP;
        }
        else if (this.state.mode === 'hspf') {
            this.state.value = this.props.valueHSPF;
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps.valueCOP !== this.props.valueCOP && this.state.mode === 'cop') {
            this.setState({ value: this.props.valueCOP });
        }
        if (prevProps.valueHSPF !== this.props.valueHSPF && this.state.mode === 'hspf') {
            this.setState({ value: this.props.valueHSPF });
        }
        if (prevProps.currentUser.unitHeatingEff !== this.props.currentUser.unitHeatingEff) {
            this.setState({
                mode: this.props.currentUser.unitHeatingEff,
                value: this.props.currentUser.unitHeatingEff === 'cop' ? HSPF_TO_COP47F(this.state.value) : COP47F_TO_HSPF(this.state.value)
            });
        }
    }

    handleChange(e, target) {
        let newMode = this.state.mode;
        let newValue = this.state.value;
        if (target === 'mode') {
            if (this.state.mode === 'cop') {
                newValue = this.state.value !== null ? COP47F_TO_HSPF(this.state.value) : null;
                newMode = 'hspf';
            }
            else {
                newValue = this.state.value !== null ? HSPF_TO_COP47F(this.state.value) : null;
                newMode = 'cop';
            }
            axios.post('/user/me/update-units', { unitHeatingEff: newMode });
            window.TEMP_UNIT_HEATING_EFF = newMode; // avoid having to call the GET user/me endpoint for other HEInput components to switch units when changed here within the same context
        }
        else if (target === 'value') {
            newValue = e.target.value === '' ? null : e.target.value;
        }

        this.setState({ mode: newMode, value: newValue });
        this.props.onChange(
            newMode === 'cop' ? newValue : (newValue !== null ? HSPF_TO_COP47F(newValue) : null),
            newMode === 'hspf' ? newValue : (newValue !== null ? COP47F_TO_HSPF(newValue) : null)
        );
    }

    render() {

        const {t} = this.props;

        return (
            <div className={`he-input-cont`}>
                <div className={`he-input-label ${this.props.extraClass ?? ''}`}>* {this.state.mode === 'cop' ? t('copRated') : t('hspfRated')}</div>
                <Button 
                    className={`he-toggle ${this.state.mode === 'cop' ? 'he-toggle-left-selected' : ''}`} 
                    onClick={(e) => {
                        this.handleChange(e, 'mode')
                    }}
                >
                    <span className='he-toggle-left'>{t('cop')}</span>
                    <span className='he-toggle-right'>{t('hspf')}</span>
                </Button>
                <br/><br/>
                <Input 
                    className='he-input'
                    type="number" 
                    name="value_input"
                    value={this.state.value}
                    onChange={(e) => this.handleChange(e, 'value')}
                />
                {this.state.mode === 'cop' && this.state.value > 5 ? <div className='he-input-warning'>{t('copWarning')}</div> : null}
                {this.state.mode === 'hspf' && this.state.value !== null && this.state.value < 6 ? <div className='he-input-warning'>{t('hspfWarning')}</div> : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { currentUser: state.auth.currentUser };
}
export default withTranslation()(withRouter(connect(mapStateToProps)(HEInput)));
