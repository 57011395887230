/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import config from './config';

import './SimpPie.scss';
import { withTranslation } from 'react-i18next';

class SimpPie extends React.Component {

  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    data: PropTypes.array,
    type: PropTypes.string
  };

  static defaultProps = {
    width: 268,
    height: 268,
    data: [],
    type: 'pre-model'
  };

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      total_type: window.HIDE_DOLLARS ? 1 : 0
    };
  }

  componentDidMount() {
    if (this.canvas !== this.canvasRef.current) {
      this.canvas = this.canvasRef.current;
      this.ctx = this.canvas.getContext('2d');
    }

    this.renderPieGraph();
  }

  componentDidUpdate(prevProps) {
    if (this.canvas !== this.canvasRef.current) {
      this.canvas = this.canvasRef.current;
      this.ctx = this.canvas.getContext('2d');
    }

    this.renderPieGraph();
  }

  renderPieGraph() {
    const data = (this.props.type === 'pre-model' || !this.props.pdata) ? this.props.data : (this.props.pdata || this.props.data);
    const width = this.props.width, height = this.props.height;

    // size canvas
    this.canvas.width = width;
    this.canvas.height = height;

    let ctx = this.ctx;

    // clear canvas
    ctx.clearRect(0, 0, width, height);

    // calc center, radii
    const cx = width * 0.5, cy = height * 0.5;
    const r_outer = width * 0.49, r_inner = width * 0.49 - 32;

    // sum values
    let totalVal = 0;
    for (let i=0; i<data.length; i++) {
      totalVal += data[i].val || 0;
    }

    // draw pie slices
    let curVal = 0;
    for (let i=0; i<data.length; i++) {
      let nextVal = curVal + (data[i].val || 0);

      ctx.beginPath();
      ctx.moveTo(cx, cy);
      ctx.arc(cx, cy, r_outer, (curVal / totalVal) * Math.PI * 2, (nextVal / totalVal) * Math.PI * 2);
      ctx.lineTo(cx, cy);
      ctx.fillStyle = data[i].color;
      ctx.fill();
      ctx.closePath();

      curVal = nextVal;
    }

    // clear center circle
    ctx.globalCompositeOperation = 'destination-out';
    ctx.beginPath();
    ctx.arc(cx, cy, r_inner, 0, Math.PI * 2);
    ctx.fillStyle = '#fff';
    ctx.fill();
    ctx.globalCompositeOperation = 'source-over';
  }

  toggleType() {
    this.setState({total_type: window.HIDE_DOLLARS ? 1 : (1-this.state.total_type)});
  }
  
  render() {

    const {t} = this.props;

    let predata = this.props.data;
    let predollars = this.props.usage_dollars;

    const data = (this.props.type === 'pre-model' || !this.props.pdata) ? this.props.data : (this.props.pdata || this.props.data);
    const total_dollars = (this.props.type === 'pre-model' || !this.props.pdata) ? this.props.usage_dollars : (this.props.usage_dollars + this.props.pusage_dollars) || this.props.usage_dollars;
    const adjusted_flag = (this.props.type === 'pre-model' || !this.props.pdata) ? this.props.adjusted_flag : this.props.padjusted_flag;
    const no_bills_flag = (this.props.type === 'pre-model' || !this.props.pdata) ? this.props.no_bills_flag : this.props.pno_bills_flag;
    const no_eh_flag = (this.props.type === 'pre-model' || !this.props.pdata) ? this.props.no_eh_flag : this.props.pno_eh_flag;

    let totalVal = 0;
    for (let i=0; i<data.length; i++) {
      data[i].val = data[i].val || 0;
      totalVal += data[i].val;
    }

    let percents = [];
    let roundingError = 100;
    for (let i=0; i<data.length; i++) {
      let percent = Math.round(100*data[i].val / totalVal);
      roundingError -= percent;
      percents.push(percent);
    } 
  
    while (roundingError) {
      for (let i=0; i<percents.length; i++) {
        if (roundingError > 0) {
          roundingError -= 1;
          percents[i] += 1;
        }
        else if (roundingError < 0) {
          roundingError += 1;
          percents[i] -= 1;
        }
      }
    }

    let preTotalVal = 0;
    for (let i=0; i<predata.length; i++) {
      preTotalVal += predata[i].val || 0;
    }
    let deltaPercent = [];
    for (let i=0; i<data.length; i++) {
      deltaPercent.push(
        Math.round(100 * data[i].val / predata[i].val - 100)
      );
    }

    let deltaTotal = [
      Math.round(total_dollars) - Math.round(predollars),
      Math.round(totalVal) - Math.round(preTotalVal)
    ];
    let deltaTotalPercent = [
      Math.round((-deltaTotal[0] / predollars * 100)),
      Math.round((-deltaTotal[1] / preTotalVal * 100))
    ];  

    return (
      <div className='simp-pie-cont'>
        <div className='sp-canvas-cont'>
          <canvas ref={this.canvasRef}/>
          <div className={'total-cont ' + (deltaTotal[this.state.total_type] !== 0 ? 'tc-extra-height' : '')}>
            <div onClick={() => this.toggleType()} className='tc-tog'>
              <i className={`la la-angle-up`} />
            </div>
            <div className='tc-total'>{this.state.total_type === 0 ? `$${total_dollars}` : `${Math.round(totalVal)} GJ`}</div>
            {this.state.total_type === 0 && deltaTotal[0] < 0 && (<div className='tc-total-delta'>-${-deltaTotal[0]} (-{Math.abs(deltaTotalPercent[0])}%)</div>)}
            {this.state.total_type === 0 && deltaTotal[0] > 0 && (<div className='tc-total-delta tc-loss'>+${deltaTotal[0]} (+{Math.abs(deltaTotalPercent[0])}%)</div>)}
            {this.state.total_type === 1 && deltaTotal[1] < 0 && (<div className='tc-total-delta'>-{-deltaTotal[1]} GJ (-{Math.abs(deltaTotalPercent[1])}%)</div>)}
            {this.state.total_type === 1 && deltaTotal[1] > 0 && (<div className='tc-total-delta tc-loss'>+{deltaTotal[1]} GJ (+{Math.abs(deltaTotalPercent[1])}%)</div>)}
            <div className='tc-per-year'>{t('perYear')}</div>
            <div onClick={() => this.toggleType()} className='tc-tog'>
              <i className={`la la-angle-down`} />
            </div>
          </div>
        </div>
        <div className='sh-label-outside'>
          <div className='sh-label-cont space-heating-label'>
            <div className='sh-box'/>
            <div className='sh-label'>
              <div className='sh-top-label'>{data[0].title}</div>
              <div className='sh-bot-label'>
                {Math.round(data[0].val)} {t('gjPerYear')} ({percents[0]}%)
                {deltaPercent[0] < 0 && (<span className='sh-delta-percent'>{deltaPercent[0]}%</span>)}
                {deltaPercent[0] > 0 && (<span className='sh-delta-percent sh-loss'>+{deltaPercent[0]}%</span>)}
              </div>
            </div>
          </div>
          <div className='sh-label-cont space-cooling-label'>
            <div className='sh-box'/>
            <div className='sh-label'>
              <div className='sh-top-label'>{data[1].title}</div>
              <div className='sh-bot-label'>
                {Math.round(data[1].val)} {t('gjPerYear')} ({percents[1]}%)
                {deltaPercent[1] < 0 && (<span className='sh-delta-percent'>{deltaPercent[1]}%</span>)}
                {deltaPercent[1] > 0 && (<span className='sh-delta-percent sh-loss'>+{deltaPercent[1]}%</span>)}
              </div>
            </div>
          </div>
          <div className='sh-label-cont lights-label'>
            <div className='sh-box'/>
            <div className='sh-label'>
              <div className='sh-top-label'>{data[2].title}</div>
              <div className='sh-bot-label'>
                {Math.round(data[2].val)} {t('gjPerYear')} ({percents[2]}%)
                {deltaPercent[2] < 0 && (<span className='sh-delta-percent'>{deltaPercent[2]}%</span>)}
                {deltaPercent[2] > 0 && (<span className='sh-delta-percent sh-loss'>+{deltaPercent[2]}%</span>)}
              </div>
            </div>
          </div>
          <div className='sh-label-cont water-label'>
            <div className='sh-box'/>
            <div className='sh-label'>
              <div className='sh-top-label'>{data[3].title}</div>
              <div className='sh-bot-label'>
                {Math.round(data[3].val)} {t('gjPerYear')} ({percents[3]}%)
                {deltaPercent[3] < 0 && (<span className='sh-delta-percent'>{deltaPercent[3]}%</span>)}
                {deltaPercent[3] > 0 && (<span className='sh-delta-percent sh-loss'>+{deltaPercent[3]}%</span>)}
              </div>
            </div>
          </div>
        </div>
        {false && no_bills_flag && <div className='sp-no-bill-msg'>* {t('msgModelUnableToMakeSufficientUtility')}</div>}
        {false && no_eh_flag && <div className='sp-no-bill-msg'>* {t('msgHomeLikelyNonElectricHeated')}</div>}
        {adjusted_flag && <div className='sp-adjusted-msg'>* {t('msgAdjustedUtilityData')}</div>}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const {t} = ownProps;
  let model = state.buildings.curModel?.LPRE || {};
  let adjusted_flag = (model?.model?.correction_factor || 1.) >= 0.6 && (model?.model?.correction_factor || 1.) <= 1.4;
  let no_bills_flag = !(model?.bills?.length);
  adjusted_flag = adjusted_flag && !no_bills_flag;
  let no_eh_flag = !adjusted_flag;

  let electricallyHeated = model?.property?.primary_heating_fuel_type == 'Electricity' && (!model?.property?.secondary_heating_fuel_type || model?.property?.secondary_heating_fuel_type == 'Electricity');
  adjusted_flag = electricallyHeated && adjusted_flag;

  let padjusted_flag = adjusted_flag;
  let pno_bills_flag = no_bills_flag;
  let pno_eh_flag = no_eh_flag;

  const base_breakdown = state?.buildings?.curModel?.LPRE?.__report_info?.bdListUnsorted || [];
  let data = [
    { color: "#4fbd6f", val: base_breakdown[0]?.usage, title: t('spaceHeating') },
    { color: "#44d9e6", val: base_breakdown[1]?.usage, title: t('spaceCooling') },
    { color: "#1a67c7", val: base_breakdown[3]?.usage, title: t('light&Appliances') },
    { color: "#d65229", val: base_breakdown[2]?.usage, title: t('hotWater') },
  ];
  let pdata = null, pmodel = null;
  let hlAdjust = 0;
  let dhwAdjust = 0;
  let cost_savings = 0;

  if (state?.buildings?.curModel?.LPU) {
    pmodel = state?.buildings?.curModel?.LPU;
    let upgrades = state?.buildings?.curModel?.LPU?.__report_info?.bundle_recommendations?.energy_advisor_bundle;
    let checks = JSON.parse(state.buildings.curBuilding.building.upgradeScenariosChecked || '[]');
    console.log("upgrades", upgrades);
    if (checks[0]) {
      const rec = pmodel?.__report_info?.recommendations?.filter((e) => !!e.is_heatpump)[0];
      hlAdjust += rec?.impact || 0.;
      cost_savings += rec?.first_year_cost_impact || 0.;
    }
    if (checks[1]) {
      dhwAdjust += -2.0;
    }
    if (checks[2]) {
      const rec = pmodel?.__report_info?.recommendations?.filter((e) => !!e.is_wall)[0];
      hlAdjust += rec?.impact || 0.;
      cost_savings += rec?.first_year_cost_impact || 0.;
    }
    if (checks[3]) {
      const rec = pmodel?.__report_info?.recommendations?.filter((e) => !!e.is_ceiling)[0];
      hlAdjust += rec?.impact || 0.;
      cost_savings += rec?.first_year_cost_impact || 0.;
    }
    if (checks[4]) {
      const rec = pmodel?.__report_info?.recommendations?.filter((e) => !!e.is_foundation)[0];
      hlAdjust += rec?.impact || 0.;
      cost_savings += rec?.first_year_cost_impact || 0.;
    }
    if (checks[5]) {
      const rec = pmodel?.__report_info?.recommendations?.filter((e) => !!e.is_windoor)[0];
      hlAdjust += rec?.impact || 0.;
      cost_savings += rec?.first_year_cost_impact || 0.;
    }

    pdata = [
      { color: "#4fbd6f", val: base_breakdown[0]?.usage + (hlAdjust || 0), title: t('spaceHeating') },
      { color: "#44d9e6", val: base_breakdown[1]?.usage, title: t('spaceCooling') },
      { color: "#1a67c7", val: base_breakdown[3]?.usage, title: t('light&Appliances') },
      { color: "#d65229", val: base_breakdown[2]?.usage + dhwAdjust, title: t('hotWater') },
    ];
    
    let pElectricallyHeated = pmodel?.property?.primary_heating_fuel_type == 'Electricity' && (!pmodel?.property?.secondary_heating_fuel_type || pmodel?.property?.secondary_heating_fuel_type == 'Electricity');
    padjusted_flag = pElectricallyHeated && padjusted_flag;
  }

  if (pno_eh_flag) {
    padjusted_flag = false;
  }
  if (no_eh_flag) {
    adjusted_flag = false;
  }

  return {
    data: data,
    pdata: pdata,
    usage_dollars: Math.round((state?.buildings?.curModel?.LPRE?.__report_info?.bundle_recommendations?.baseline?.graphs?.baseline_energy_bill_cost_graph || [])[0]),
    pusage_dollars: Math.round(cost_savings),//Math.round(state?.buildings?.curModel?.LPU?.__report_info?.bundle_recommendations?.energy_advisor_bundle?.rec_totals?.post_upgrade?.annual_energy_cost) - state?.buildings?.curModel?.LPRE?.__report_info?.yearly_cost,
    adjusted_flag,
    padjusted_flag,
    no_bills_flag,
    padjusted_flag,
    no_eh_flag,
    pno_eh_flag,
    pno_bills_flag
  };
}
export default withTranslation()(withRouter(connect(mapStateToProps)(SimpPie)));
