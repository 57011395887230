/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, FormGroup, Label, Input, Collapse } from 'reactstrap';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getBuildingRequest, getBuildingRequestLM, updateBuildingRequest } from '../../../../actions/buildings';
import './PostUpgradePanel.scss';
import s from './PostUpgradePanel.module.scss';
import config from './config';
import SimpPie from './SimpPie';
import { listsData } from "../../selectLists"
import SimpHLChart from '../heatloss-chart/SimpHLChart';
import { is } from '@amcharts/amcharts4/core';
import { withTranslation } from 'react-i18next';

const colors = config.chartColors;
// eslint-disable-next-line
let columnColors = [colors.blue, colors.green, colors.orange, colors.red, colors.default, colors.gray, colors.teal, colors.pink];
// eslint-disable-next-line
let lineColors = [colors.blue, colors.green, colors.orange];

class PostUpgradePanel extends React.Component {

  static propTypes = {
    model: PropTypes.object,
    onGotoNext: PropTypes.func
  };

  static defaultProps = {
    model: {}
  };

  componentDidMount() {

    const buildingId  = this.props.match.params.buildingId;

    this.props.dispatch(getBuildingRequest(buildingId));
    this.props.dispatch(getBuildingRequestLM(buildingId));
  }

  constructor(props) {
    super(props);

    // overview: Known
    // accuracy: Model Accuracy
    // analysis: Model Assumptions
    // recommendation: Estimated Type N Upgrades

    // bonus: scatter chart: plot actual (points) and regression line (from cofficients) - y: axis (kWh) - x: heating degree days (HDD)

    this.handleGotoReport = this.handleGotoReport.bind(this);

    this.state = {
      rand: Math.floor(Math.random()*1e9)+1e8
    };

    this.toggleAccordionFirst = this.toggleAccordionFirst.bind(this);

    this.lastModelStr = JSON.stringify({});
  }

  componentDidUpdate(prevProps) {

    let newModelStr = JSON.stringify(this.props.model) + JSON.stringify(this.props.recommendations) + JSON.stringify(this.props.pmodel);

    if (newModelStr === this.lastModelStr) {
      return;
    }

    this.lastModelStr = newModelStr;

    let aContent = [
    ];

    for (let i=0; this.props.recommendations && i<this.props.recommendations.length; i++) {
      const R = this.props.recommendations[i];
      aContent.push({
        title: R.name,
        body: ``,
        impact: R.impact,
        dollar_impact: R.dollar_impact,
        first_year_cost_impact: -R.first_year_cost_impact,
        efficiency_ns_ohpa: (R.efficiency_ns_ohpa && R.efficiency_ns_ohpa != "$NaN") ? R.efficiency_ns_ohpa : undefined,
        efficiency_ns_moderate_income: (R.efficiency_ns_moderate_income && R.efficiency_ns_moderate_income != "$NaN") ? R.efficiency_ns_moderate_income : undefined,
        nbp_total_homes: (R.nbp_total_homes && R.nbp_total_homes != "$NaN") ? R.nbp_total_homes : undefined,
        /*summary_text: R.summary_text,
        summary_text2: R.summary_text2,*/
        savings_10_year_text: R.savings_10_year_text,
        savings_15_year_text: R.savings_15_year_text,
        savings_20_year_text: R.savings_20_year_text,
        yellow: R.yellow,
        red: R.red,
        trees_text: R.trees_text,
        payback_text: R.payback_text,
        cost_text: R.cost_text
      });
    }

    if ((this.props.isAdmin||this.props.isUtilityAdmin) && this.props.pmodel) {

      let allFields = [];

      let P = { ...(this.props.pmodel) };

      if (P.building.type_2_model_debug) {
        //allFields = [...allFields, ...(_AutoFindFields(P.building.type_2_model_debug, 'P.building.type_2_model_debug', 'Debug'))];
      }
      if (P.building.type_2_breakdown_model_debug) {
        //allFields = [...allFields, ...(_AutoFindFields(P.building.type_2_breakdown_model_debug, 'P.building.type_2_breakdown_model_debug', 'Debug'))];
      }

      let t2debugCsv = { id: P.id, rec_models: P.building.type_2_model_debug || {}, breakdown: P.building.type_2_breakdown_model_debug };

      if (true) {//allFields.length) {
        let initFields = [];
        for (let i = 0; i < allFields.length; i++) {
          const F = allFields[i];
          let value = undefined;
          try {
            eval(`value = ${F.key};`);
          }
          catch (erri) {
            value = undefined;
          }
          if (F.type === 'bool') {
            value = !!value;
          }
          else {
            if (value === undefined || value === null) {
              value = '';
            }
            else {
              if (typeof value === 'number' && (value !== Math.floor(value))) {
                value = `${value.toFixed(F.int ? 0 : 3)}`;
              }
              else {
                value = `${value}`;
              }
            }
          }
          let doAdd = !F.add_if;
          if (F.add_if) {
            try {
              eval(`doAdd = !!(${F.add_if})`);
            }
            catch (errif) {
              doAdd = false;
            }
          }
          if (doAdd) {
            initFields.push({ ...F, value: value, rkey: `pm-ifield-${F.key}` });
          }
        }

        let filterFields = (args, rowSize) => {
          let ret = [];
          for (let i = 0; i < initFields.length; i++) {
            const F = initFields[i];
            for (let key in args) {
              if (F[key] === args[key]) {
                ret.push(F);
                break;
              }
            }
          }
          let r2 = [];
          let bfr = [];
          for (let i = 0; i < ret.length; i++) {
            bfr.push(ret[i]);
            if (bfr.length === rowSize) {
              r2.push(bfr);
              bfr = [];
            }
          }
          if (bfr.length) {
            r2.push(bfr);
          }
          return r2;
        };

        aContent.push({
          title: 'T2 Model Debug (Model + Raw ML IO)', body: '',
          fields: filterFields({ type2debug: true }, 2),
          downloadCsv: { P, isPre: false },
          debugCsv: { P: t2debugCsv, isPre: false }
        });
      }
    }

    let a1Array = [];
    a1Array.length = aContent.length;
    a1Array.fill(false);
    a1Array[-2] = true;
    a1Array[-1] = false;

    this.setState({
      init: true,
      ld: '',
      initEchartsOptions: {
        renderer: 'canvas'
      },
      hoverData: null,
      dropdownOpen: false,
      accordionFirst: a1Array,
      accordionFirstContent: aContent,
      rand: Math.floor(Math.random()*1e9)+1e8
    });
  }

  toggleAccordionFirst(id) {
    const arr = [];
    arr.length = this.state.accordionFirst.length;
    arr.fill(false);
    arr[id] = !this.state.accordionFirst[id];
    this.setState({
      accordionFirst: arr,
    });
  }

  setHoverData = (e) => {
    this.setState({ hoverData: e.target.category });
  }

  renderPieGraph(title) {
    return (
      <SimpPie
        width={268}
        height={268}
        type={"post-model"}
      />
    );
  }

  renderHLChart(title) {
    const P = this.props.pmodel;
    const PRE = this.props.pre_model;
    if (!PRE) {
      return (<div/>);
    }

    return (
      <SimpHLChart
        width={268}
        height={268}
        walls_hl={(P?.yearly_heatloss_breakdown_gj?.walls || PRE.yearly_heatloss_breakdown_gj.walls) || 0}
        foundation_hl={(P?.yearly_heatloss_breakdown_gj?.foundation || PRE.yearly_heatloss_breakdown_gj.foundation) || 0}
        windows_hl={(P?.yearly_heatloss_breakdown_gj?.windows_doors || PRE.yearly_heatloss_breakdown_gj.windows_doors) || 0}
        ceiling_hl={(P?.yearly_heatloss_breakdown_gj?.ceiling || PRE.yearly_heatloss_breakdown_gj.ceiling) || 0}
        comp_walls_hl={PRE.yearly_heatloss_breakdown_gj.walls || 0}
        comp_foundation_hl={PRE.yearly_heatloss_breakdown_gj.foundation || 0}
        comp_windows_hl={PRE.yearly_heatloss_breakdown_gj.windows_doors || 0}
        comp_ceiling_hl={PRE.yearly_heatloss_breakdown_gj.ceiling || 0}
        no_found={!PRE.property.heated_basement}
      />
    );
  }

  handleGotoReport() {
    if (this.props.onGotoNext) {
      this.props.onGotoNext();
    }
  }

  /*renderPostHL(){
    if (!this.props.pmodel || !this.props.pmodel.building || !this.props.pmodel.building.type_2_postupgrade_heatloss) {
      return (<div/>);
    }
    return (
      <div className='post-hl-fg'>
        <FormGroup row>
          <Col xs={6}>
            <Label for="normal-field">Wall Heatloss (GJ/year)</Label>
            <Input type="text" placeholder="800" readOnly={true} defaultValue={new Number(this.props.pmodel.building.type_2_postupgrade_heatloss.walls_heatloss_gj_year).toFixed(3)} name='basementFloorArea' innerRef={this.cooling} />
          </Col>
          <Col xs={6}>
            <Label for="normal-field">Foundation Heatloss (GJ/year)</Label>
            <Input type="text" placeholder="800" readOnly={true} defaultValue={new Number(this.props.pmodel.building.type_2_postupgrade_heatloss.foundation_heatloss_gj_year).toFixed(3)} name='basementFloorArea' innerRef={this.heating} />
          </Col>
          <Col xs={6}>
            <Label for="normal-field">Ceiling Heatloss (GJ/year)</Label>
            <Input type="text" placeholder="800" readOnly={true} defaultValue={new Number(this.props.pmodel.building.type_2_postupgrade_heatloss.ceiling_heatloss_gj_year).toFixed(3)} name='basementFloorArea' innerRef={this.hot_water} />
          </Col>
          <Col xs={6}>
            <Label for="normal-field">Window Heatloss (GJ/year)</Label>
            <Input type="text" placeholder="800" readOnly={true} defaultValue={new Number(this.props.pmodel.building.type_2_postupgrade_heatloss.window_heatloss_gj_year).toFixed(3)} name='basementFloorArea' innerRef={this.lights_appliances} />
          </Col>
          <Col xs={6}>
            <Label for="normal-field">Total Heatloss (GJ/Year)</Label>
            <Input type="text" placeholder="800" readOnly={true} defaultValue={new Number(this.props.pmodel.building.type_2_postupgrade_heatloss.walls_heatloss_gj_year + this.props.pmodel.building.type_2_postupgrade_heatloss.foundation_heatloss_gj_year + this.props.pmodel.building.type_2_postupgrade_heatloss.ceiling_heatloss_gj_year + this.props.pmodel.building.type_2_postupgrade_heatloss.window_heatloss_gj_year).toFixed(3)} name='basementFloorArea' innerRef={this.lights_appliances} />
          </Col>
          <br/>
          <br/>
        </FormGroup>
      </div>
    );
  }*/

  changeField(field) {

  }

  render() {

    const {t} = this.props;

    const renderField = (field) => {
      return (
        <div className='ac-field' key={field.rkey}>
          <Col xs={12}>
            <Label for="normal-field">{field.title}</Label>
          </Col>
          <Col xs={12}>
            <Input type="text" defaultValue={field.value} name={field.key} readOnly></Input>
          </Col>
        </div>
      );
    };

    if (!this.state.init) {
      return (<div/>);
    }
    return (
      <div>
        <h1 className="pane-title" style={{marginBottom: "40px"}}>{t('upgradeModel')}<span
          className="fw-semi-bold"
        ></span></h1>
        {/* Accordion */}
        <Row className="mt-xs">
          <Col md="12" xs="12" className='mb-lg'>
            <div className="ac-cont panel mb-xs" key={`accord-one--2`}>
              { /* eslint-disable */}
              <div
                className="ac-header panel-header" role="button"
                onClick={() => {
                  this.toggleAccordionFirst(-2)
                }}>
                { /* eslint-enable */}
                <div className="mb-0">
                  {/* eslint-disable-next-line */}
                  <a className="accordion-toggle" role="button">
                    <div className="ac-header-text">{t('energyBreakdownChart')}</div>
                    <i className={`fa fa-angle-down ${this.state.accordionFirst[-2] ? 'expanded' : ''}`} />
                  </a>
                </div>
              </div>
              <Collapse className="panel-body" isOpen={this.state.accordionFirst[-2]}>
                <div>
                  {this.renderPieGraph("Pie")}
                </div>
              </Collapse>
            </div>
             <div className="ac-cont panel mb-xs" key={`accord-one--1-${this.state.rand}`}>
              { /* eslint-disable */}
              <div
                className="ac-header panel-header" role="button"
                onClick={() => {
                  this.toggleAccordionFirst(-1)
                }}>
                { /* eslint-enable */}
                <div className="mb-0">
                  {/* eslint-disable-next-line */}
                  <a className="accordion-toggle" role="button">
                    <div className="ac-header-text">{t('postUpgradeHeatLoss')}</div>
                    <i className={`fa fa-angle-down ${this.state.accordionFirst[-1] ? 'expanded' : ''}`} />
                  </a>
                </div>
              </div>
              <Collapse className="panel-body" isOpen={this.state.accordionFirst[-1]}>
                <div>
                  {this.renderHLChart('Heat Loss')}
                </div>
              </Collapse>
            </div>
            {this.state.accordionFirstContent.map((element, index) => (
              <div className="ac-cont panel mb-xs" key={`accord-one-${index.toString()}-${this.state.rand}`}>
                { /* eslint-disable */}
                <div
                  className="ac-header panel-header" role="button"
                  onClick={() => {
                    this.toggleAccordionFirst(index)
                  }}>
                  { /* eslint-enable */}
                  <div className="mb-0">
                    {/* eslint-disable-next-line */}
                    <a className="accordion-toggle" role="button">
                      <div className="ac-header-text" style={element.red ? {color: '#AA3333'} : element.yellow ? {color: '#AAAA33'} : {}}>{element.title}</div>
                      <i className={`fa fa-angle-down ${this.state.accordionFirst[index] ? 'expanded' : ''}`} />
                    </a>
                  </div>
                </div>
                <Collapse className="panel-body" isOpen={this.state.accordionFirst[index]}>
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: element.body }} />
                    {element.downloadCsv && <div>
                      <Button className='mr-xs btn btn-primary' onClick={() => ( window.DownloadModelCSV(element.downloadCsv.P, element.downloadCsv.isPre) )}>{t('downloadPostUpgradeCSV')}</Button><br/><br/>
                      <Button className='mr-xs btn btn-primary' onClick={() => ( window.DownloadModelCSV(element.debugCsv.P, element.downloadCsv.isPre, true) )}>{t('downloadt2PostDebugCSV')}</Button>
                      <br/></div>}
                    <br />
                    <div className="ac-field-list">
                      {element.fields && element.fields.map((fieldRow, rIndex) => (
                        <FormGroup row key={`_fg-${index}-${rIndex}`}>
                          {fieldRow.map((field, cIndex) => renderField(field))}
                        </FormGroup>
                      ))}
                    </div>
                    <div>{element.component}</div>
                    {/* {this.renderGraph(element.title)} */}
                    {!element.downloadCsv && <Col xs={12}>
                      <Label for={"est-impact-gj" + index}>{t('estimatedRecommendationSaving')}</Label>
                    </Col>}
                    {!element.downloadCsv && <Col xs={12}>
                      <Input type="text" defaultValue={`${element.impact > 0 ? '' : ''}${Math.round(element.impact*100)/100} ${t('gjPerYear')}`} name={"est-impact-gj" + index} readOnly style={{'display': 'inline-block', width: '48%', marginRight: '2%'}}/>
                      <br/>
                      <br/>
                    </Col>}
                    {!window.HIDE_DOLLARS && !element.downloadCsv && <Col xs={12}>
                      <Label for={"est-impact-cost-f" + index} style={{'display': 'inline-block', width: '48%', 'margin-right': '8px'}}>{t('estimatedRecommendationCostSavingFirstYear')}</Label>
                      <Label for={"est-impact-cost-a" + index} style={{'display': 'inline-block', width: '48%'}}>{t('estimatedRecommendationCostSavingAverage')}</Label>
                    </Col>}
                    {!window.HIDE_DOLLARS && !element.downloadCsv && <Col xs={12}>
                      {element.first_year_cost_impact < 0 && (<Input type="text" defaultValue={`-$${-Math.round(element.first_year_cost_impact)}`} name={"est-impact-dlr" + index} readOnly style={{'display': 'inline-block', width: '48%', 'margin-right': '8px'}} />)}
                      {element.first_year_cost_impact >= 0 && (<Input type="text" defaultValue={`$${Math.round(element.first_year_cost_impact)}`} name={"est-impact-dlr" + index} readOnly style={{'display': 'inline-block', width: '48%', 'margin-right': '8px'}} />)}
                      {element.dollar_impact < 0 && (<Input type="text" defaultValue={`-$${-Math.round(element.dollar_impact)} ${t('perYear')}`} name={"est-impact-dlr" + index} readOnly style={{'display': 'inline-block', width: '48%'}} />)}
                      {element.dollar_impact >= 0 && (<Input type="text" defaultValue={`$${Math.round(element.dollar_impact)} ${t('perYear')}`} name={"est-impact-dlr" + index} readOnly style={{'display': 'inline-block', width: '48%'}} />)}
                      <br/>
                      <br/>
                    </Col>}

                    {false && !element.downloadCsv && !window.HIDE_REBATES && (element.summary_text || element.summary_text2) && <Col xs={12}>
                      <Label for={"est-impact-gj" + index}>{t('recommendationSummary')}</Label>
                    </Col>}
                    {false && !element.downloadCsv && !window.HIDE_REBATES && (element.summary_text || element.summary_text2) && <Col xs={12}>
                      <div className="est-rec-summary" dangerouslySetInnerHTML={{ __html: ((element.summary_text||'') + "<br/><br/>" + (element.summary_text2||'')) || t("unavailable") }}></div>
                      <br/>
                    </Col>}
                    {!element.downloadCsv && !window.HIDE_REBATES && (element.efficiency_ns_ohpa || element.efficiency_ns_moderate_income || element.nbp_total_homes) && <Col xs={12}>
                      <Label for={"est-impact-gj" + index}>{t('estimatedUpgradeRebates')}</Label>
                    </Col>}
                    {!element.downloadCsv && !window.HIDE_REBATES && (element.efficiency_ns_ohpa || element.efficiency_ns_moderate_income || element.nbp_total_homes) && <Col xs={12}>
                      {!window.HIDE_REBATES && element.efficiency_ns_ohpa && (<Input type="text" defaultValue={`${t('ohpa')}: ` + element.efficiency_ns_ohpa} name={"est-rebate-eff-ns" + index} readOnly style={{'display': 'inline-block', width: '48%', marginRight: '2%'}} />)}
                      {!window.HIDE_REBATES && element.efficiency_ns_moderate_income && (<Input type="text" defaultValue={`${t('moderateIncome')}: ` + element.efficiency_ns_moderate_income} name={"est-rebate-gh" + index} readOnly style={{'display': 'inline-block', width: '48%'}} />)}
                      {!window.HIDE_REBATES && element.nbp_total_homes && (<Input type="text" defaultValue={`${t('nbpTotalHomes')}: ` + element.nbp_total_homes} name={"est-rebate-nbpth" + index} readOnly style={{'display': 'inline-block', width: '98%'}} />)}
                      <br/>
                      <br/>
                    </Col>}
                    {!element.downloadCsv && !window.HIDE_REBATES && (element.payback_text || element.cost_text || element.savings_10_year_text ||  element.savings_15_year_text || element.savings_20_year_text || element.trees_text) && <Col xs={12}>
                      <Label for={"est-impact-gj" + index}>{t('costLongTermSavings')}</Label>
                    </Col>}
                    {!element.downloadCsv && !window.HIDE_REBATES && (element.payback_text || element.cost_text) && <Col xs={12}>
                      {!window.HIDE_REBATES && element.payback_text && (<Input type="text" defaultValue={`${t('paybackPeriod')}: ` + element.payback_text} name={"est-rebate-pp" + index} readOnly style={{'display': 'inline-block', width: '48%', marginRight: '2%'}} />)}
                      {!window.HIDE_REBATES && element.cost_text && (<Input type="text" defaultValue={`${t('costs')}: ` + element.cost_text} name={"est-rebate-cost" + index} readOnly style={{'display': 'inline-block', width: '48%'}} />)}
                      <br/>
                      <br/>
                    </Col>}
                    {!element.downloadCsv && !window.HIDE_REBATES && (element.savings_10_year_text || element.savings_20_year_text || element.savings_15_year_text) && <Col xs={12}>
                      {!window.HIDE_REBATES && element.savings_10_year_text && (<Input type="text" defaultValue={`${t('tenYearSavings')}: ` + element.savings_10_year_text} name={"est-rebate-10ys" + index} readOnly style={{'display': 'inline-block', width: '48%', marginRight: '2%', backgroundColor: element.yellow ? '#666622' : undefined}} />)}
                      {!window.HIDE_REBATES && element.savings_20_year_text && !element.savings_15_year_text && (<Input type="text" defaultValue={`${t('twentyYearSavings')}: ` + element.savings_20_year_text} name={"est-rebate-20ys" + index} readOnly style={{'display': 'inline-block', width: '48%', backgroundColor: element.red ? '#662222' : undefined}} />)}
                      {!window.HIDE_REBATES && element.savings_15_year_text && (<Input type="text" defaultValue={`${t('fifteenYearSavings')}: ` + element.savings_15_year_text} name={"est-rebate-20ys" + index} readOnly style={{'display': 'inline-block', width: '48%', backgroundColor: element.red ? '#662222' : undefined}} />)}
                      <br/>
                      <br/>
                    </Col>}
                    {!window.HIDE_REBATES && element.trees_text && (<Col xs={12}><Input type="text" defaultValue={element.trees_text} name={"est-rebate-ei" + index} readOnly style={{'display': 'inline-block', width: '98%'}} /><br/><br/></Col>)}
                  </div>
                </Collapse>
              </div>
            ))}
          </Col>
        </Row>
      </div>);
  }
}

function mapStateToProps(state, ownProps) {
  const { t } = ownProps;
  let model = state.buildings.curModel || {};
  let building = state.buildings.curBuilding.building || {};
  let pmodel = model.LPU;
  let recos = [];
  let user = state.auth.currentUser;
  if (pmodel) {
    let checks = JSON.parse(building.upgradeScenariosChecked || '[]');
    let rate = pmodel?.__report_info?.ratePerGJ;

    const rec_heat_pump = pmodel?.__report_info?.recommendations?.filter((e) => !!e.is_heatpump)[0];
    const rec_walls = pmodel?.__report_info?.recommendations?.filter((e) => !!e.is_wall)[0];
    const rec_ceiling = pmodel?.__report_info?.recommendations?.filter((e) => !!e.is_ceiling)[0];
    const rec_foundation = pmodel?.__report_info?.recommendations?.filter((e) => !!e.is_foundation)[0];
    const rec_window_door = pmodel?.__report_info?.recommendations?.filter((e) => !!e.is_windoor)[0];
    const bundle_rec_heat_pump = pmodel?.__report_info?.bundle_recommendations?.energy_advisor_bundle?.recommendations?.filter((e) => e?.recommendations_name?.includes('heat_pump'))[0];
    const bundle_rec_walls = pmodel?.__report_info?.bundle_recommendations?.energy_advisor_bundle?.recommendations?.filter((e) => e?.recommendations_name?.includes('upgrade_walls'))[0];
    const bundle_rec_ceiling = pmodel?.__report_info?.bundle_recommendations?.energy_advisor_bundle?.recommendations?.filter((e) => e?.recommendations_name?.includes('upgrade_ceiling'))[0];
    const bundle_rec_foundation = pmodel?.__report_info?.bundle_recommendations?.energy_advisor_bundle?.recommendations?.filter((e) => e?.recommendations_name?.includes('upgrade_foundation'))[0];
    const bundle_rec_window_door = pmodel?.__report_info?.bundle_recommendations?.energy_advisor_bundle?.recommendations?.filter((e) => e?.recommendations_name?.includes('upgrade_windows_doors'))[0];

    recos = [
      {
        name: t('heatPumpUpgrade'),
        enabled: !!checks[0],
        impact: rec_heat_pump?.impact,
        dollar_impact: rec_heat_pump?.est_savings_dollars,
        efficiency_ns_ohpa: pmodel?.__report_info?.bundle_recommendations?.energy_advisor_bundle?.rec_totals?.rebate_list?.efficiency_ns_ohpa?.value ? '$' + pmodel?.__report_info?.bundle_recommendations?.energy_advisor_bundle?.rec_totals?.rebate_list?.efficiency_ns_ohpa?.value : 0,
        efficiency_ns_moderate_income: pmodel?.__report_info?.bundle_recommendations?.energy_advisor_bundle?.rec_totals?.rebate_list?.efficiency_ns_moderate_income?.value ? '$' + pmodel?.__report_info?.bundle_recommendations?.energy_advisor_bundle?.rec_totals?.rebate_list?.efficiency_ns_moderate_income?.value : 0,
        nbp_total_homes: bundle_rec_heat_pump?.rebate_list?.nbp_total_homes?.value ? '$' + bundle_rec_heat_pump?.rebate_list?.nbp_total_homes?.value : 0,
        trees_text: rec_heat_pump?.trees_text,
        payback_text: rec_heat_pump?.payback_text,
        savings_10_year_text: rec_heat_pump?.savings_10_year_text,
        savings_15_year_text: rec_heat_pump?.savings_15_year_text,
        summary_text: rec_heat_pump?.rec_text,
        summary_text2: rec_heat_pump?.save_text,
        cost_text: rec_heat_pump?.cost_text,
        first_year_cost_impact: rec_heat_pump?.first_year_cost_impact,
        yellow: parseFloat(((rec_heat_pump?.savings_10_year_text||"")+"").replace('$', '')) < 0,
        red: parseFloat(((rec_heat_pump?.savings_15_year_text||"")+"").replace('$', '')) < 0
      },
      /*{
        name: 'Domestic Hot Water Upgrade',
        enabled: !!checks[1],
        impact: -2.0
      },*/
      {
        name: t('wallInsulationUpgrade'),
        enabled: !!checks[2],
        impact: rec_walls?.impact,
        dollar_impact: rec_walls?.est_savings_dollars,
        efficiency_ns_ohpa: rec_walls?.eff_ns_reb_text,
        efficiency_ns_moderate_income: rec_walls?.gh_reb_text,
        nbp_total_homes: bundle_rec_walls?.rebate_list?.nbp_total_homes?.value ? '$' + bundle_rec_walls?.rebate_list?.nbp_total_homes?.value : 0,
        trees_text: rec_walls?.trees_text,
        payback_text: rec_walls?.payback_text,
        savings_10_year_text: rec_walls?.savings_10_year_text,
        savings_20_year_text: rec_walls?.savings_20_year_text,
        summary_text: rec_walls?.rec_text,
        summary_text2: rec_walls?.save_text,
        cost_text: rec_walls?.cost_text,
        first_year_cost_impact: rec_walls?.first_year_cost_impact,
        yellow: parseFloat(((rec_walls?.savings_10_year_text||"")+"").replace('$', '')) < 0,
        red: parseFloat(((rec_walls?.savings_20_year_text||"")+"").replace('$', '')) < 0
      },
      {
        name: t('ceilingInsulationUpgrade'),
        enabled: !!checks[3],
        impact: rec_ceiling?.impact,
        dollar_impact: rec_ceiling?.est_savings_dollars,
        efficiency_ns_ohpa: rec_ceiling?.eff_ns_reb_text,
        efficiency_ns_moderate_income: rec_ceiling?.gh_reb_text,
        nbp_total_homes: bundle_rec_ceiling?.rebate_list?.nbp_total_homes?.value ? '$' + bundle_rec_ceiling?.rebate_list?.nbp_total_homes?.value : 0,
        trees_text: rec_ceiling?.trees_text,
        payback_text: rec_ceiling?.payback_text,
        savings_10_year_text: rec_ceiling?.savings_10_year_text,
        savings_20_year_text: rec_ceiling?.savings_20_year_text,
        summary_text: rec_ceiling?.rec_text,
        summary_text2: rec_ceiling?.save_text,
        cost_text: rec_ceiling?.cost_text,
        first_year_cost_impact: rec_ceiling?.first_year_cost_impact,
        yellow: parseFloat(((rec_ceiling?.savings_10_year_text||"")+"").replace('$', '')) < 0,
        red: parseFloat(((rec_ceiling?.savings_20_year_text||"")+"").replace('$', '')) < 0
      },
      {
        name: t('foundationInsulationUpgrade'),
        enabled: !!checks[4] && building.basementFloorArea > 0,
        impact: rec_foundation?.impact,
        dollar_impact: rec_foundation?.est_savings_dollars,
        efficiency_ns_ohpa: rec_foundation?.eff_ns_reb_text,
        efficiency_ns_moderate_income: rec_foundation?.gh_reb_text,
        nbp_total_homes: bundle_rec_foundation?.rebate_list?.nbp_total_homes?.value ? '$' + bundle_rec_foundation?.rebate_list?.nbp_total_homes?.value : 0,
        payback_text: rec_foundation?.payback_text,
        trees_text: rec_foundation?.trees_text,
        savings_10_year_text: rec_foundation?.savings_10_year_text,
        savings_20_year_text: rec_foundation?.savings_20_year_text,
        summary_text: rec_foundation?.rec_text,
        summary_text2: rec_foundation?.save_text,
        cost_text: rec_foundation?.cost_text,
        first_year_cost_impact: rec_foundation?.first_year_cost_impact,
        yellow: parseFloat(((rec_foundation?.savings_10_year_text||"")+"").replace('$', '')) < 0,
        red: parseFloat(((rec_foundation?.savings_20_year_text||"")+"").replace('$', '')) < 0
      },
      {
        name: t('estarWindowsDoors'),
        enabled: !!checks[5],
        impact: rec_window_door?.impact,
        dollar_impact: rec_window_door?.est_savings_dollars,
        efficiency_ns_ohpa: rec_window_door?.eff_ns_reb_text,
        efficiency_ns_moderate_income: rec_window_door?.gh_reb_text,
        nbp_total_homes: bundle_rec_window_door?.rebate_list?.nbp_total_homes?.value ? '$' + bundle_rec_window_door?.rebate_list?.nbp_total_homes?.value : 0,
        payback_text: rec_window_door?.payback_text,
        trees_text: rec_window_door?.trees_text,
        savings_10_year_text: rec_window_door?.savings_10_year_text,
        savings_20_year_text: rec_window_door?.savings_20_year_text,
        summary_text: rec_window_door?.rec_text,
        summary_text2: rec_window_door?.save_text,
        cost_text: rec_window_door?.cost_text,
        first_year_cost_impact: rec_window_door?.first_year_cost_impact,
        yellow: parseFloat(((rec_window_door?.savings_10_year_text||"")+"").replace('$', '')) < 0,
        red: parseFloat(((rec_window_door?.savings_20_year_text||"")+"").replace('$', '')) < 0
      }
    ];
    for (let i=0; i<recos.length; i++) {
      if (!recos[i].enabled) {
        recos.splice(i, 1);
        i --;
        continue;
      }
      else {
        recos[i].impact = -recos[i].impact;
        //recos[i].dollar_impact = -Math.round(-recos[i].impact * rate);
      }
    }
  }
  
  return {
    model,
    pre_model: model.LPRE,
    recommendations: recos,
    pmodel,
    isAdmin: user && user.role === 'admin',
    isUtilityAdmin: user && user.role === 'utilityadmin'
  };
}
export default withTranslation()(withRouter(connect(mapStateToProps)(PostUpgradePanel)));
