import moment from "moment";
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getBuildingsRequest } from "../../actions/buildings";
import axios from 'axios';
import "./ProgramOverview.scss";
import { Trans, withTranslation } from "react-i18next";
import { Button } from "reactstrap";

class ProgramOverview extends React.Component {
	static propTypes = {
		customers: PropTypes.array,
		dispatch: PropTypes.func.isRequired,
		buildings: PropTypes.object,
	};
	static defaultProps = {
		customers: [
		],
	};

	state = {
		popovers: {},
		promoAlert: false,
		filter: null,
	};

	constructor() {
		super();
	}

	componentDidMount() {
		this.props.dispatch(getBuildingsRequest());
		if (this.resizeEvent) {
			window.removeEventListener("resize", this.resizeEvent);
			this.resizeEvent = null;
		}
		window.addEventListener("resize", this.resizeEvent = (() => {this.setState({rand: Math.random()})}));
	}

	componentWillUnmount() {
		if (this.resizeEvent) {
			window.removeEventListener("resize", this.resizeEvent);
			this.resizeEvent = null;
		}
	}

    async downloadOverview() {
        let resp = await axios.get(`/v3/programs/${this.props.currentUser.programIds[0]}/projects/`, { headers: { Accept: "application/csv" } });
        let text = resp.data;
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', 'program-overview.csv');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

	render() {
		const { t } = this.props;
		return (
			<div>
				<div className="page-top-line">
					<h1 className="header" style={{ width: "100%", marginTop: "0px", marginLeft: "0px", color: "#fff", fontSize: "26px", fontWeight: "bolder" }}>
						{t('program-overview')}
					</h1>
				</div>
                <div>
                    <br/><br/>
                    <Button className='btn btn-primary' onClick={() => this.downloadOverview()}>{t('export-program-overview')}</Button>
                </div>
			</div >
		);
	}
}


function mapStateToProps(state) {
	let buildings = state.buildings.data || {};
	return {
        currentUser: state.auth.currentUser,
		buildings
	};
}
export default withTranslation()(withRouter(connect(mapStateToProps)(ProgramOverview)));
