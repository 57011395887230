import moment from "moment";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import axios from 'axios';
import "../energy-advisor/EAInvitePage.scss";
import { withTranslation } from "react-i18next";

class EAInvitePage extends React.Component {
	static propTypes = {
	};

	static defaultProps = {
	};

	state = {
        inviteData: [
            /*{ userEmailAddress: 'test1@test.ca', userRole: 1, invitationStatus: 1, invitationLastSent: "1720462039517", expiresOn: "1721066839034" },
            { userEmailAddress: 'test2@test.ca', userRole: 0, invitationStatus: 2, invitationLastSent: "1720462039517", expiresOn: "1721066839034" },
            { userEmailAddress: 'test3@test.ca', userRole: 1, invitationStatus: 3, invitationLastSent: "1720462039517", expiresOn: "1721066839034" }*/
        ]
	};

	constructor() {
		super();

        this.refreshInviteList();
	}

    async refreshInviteList() {
        try {
            let resp = await axios.get('/v3/users/invite');
            if (resp.data instanceof Array) {
                this.setState({ inviteData: resp.data, rand: Math.random() });
            }
        }
        catch (err) {
            console.error('EAInvitePage: Error loading invite list');
        }
    }

    async sendInvite(data) {
        try {
            let resp = await axios.post('/v3/users/invite', data);
            if (resp.status === 200) {
                return true;
            }
            return false;
        }
        catch (err) {
            console.error('EAInvitePage: Error sending invite');
            return false;
        }
    }

    async resendInvite(invite) {
        try {
            let resp = await axios.post(`/v3/users/invite/${invite.invitationId}/resend`);
            if (resp.status !== 200) {
                throw "error";
            }
            return true;
        }
        catch (err) {
            console.error('EAInvitePage: Error resending invite');
            return false;
        }
    }

	componentDidMount() {
	}

	componentWillUnmount() {
	}

	render() {
        const { t } = this.props;
		return (
			<div className="ea-invite-advisor-page">
                <h1>{t('inviteAdvisorToPlatform')}</h1>
                <br/>
                <label for="eaia-email-input">{t('emailAddressToInvite')}:</label>
                <input id="eaia-email-input" />
                <br/>
                <br/>
                <label for="eaia-role-input">{t('userRole')}:</label>
                <select id="eaia-role-input">
                    <option value="ra">{t('energyAdvisor')}</option>
                    <option value="so">{t('Service Organization Manager')}</option>
                </select>
                <br/>
                <br/>
                <button onClick={async () => {
                    let data = {
                        emailAddress: document.getElementById('eaia-email-input').value.trim(),
                        userRole: document.getElementById('eaia-role-input').value.trim()
                    };
                    if (!data.emailAddress.length || !data.userRole.length) {
                        alert(t('fillInAllFields'));
                    }
                    else {
                        if (await this.sendInvite(data)) {
                            document.getElementById('eaia-email-input').value = '';
                            document.getElementById('eaia-role-input').value = '';
                            this.refreshInviteList();
                        }
                    }
                }}>{t('sendInvite')}</button>
                <br/>
                <br/>
                <hr/>
                <h1>{t('pastInvites')}</h1>
                <table>
                    <tr>
                        <th>{t('emailAddress')}</th>
                        <th>{t('role')}</th>
                        <th>{t('status')}</th>
                        <th>{t('lastSentOn')}</th>
                        <th>{t('expiresOn')}</th>
                        <th>{t('inviteActions')}</th>
                    </tr>
                    {this.state.inviteData.map((invite) => (<tr>
                        <td>{invite.userEmailAddress}</td>
                        <td>{{1:t("energyAdvisor"), 0:t("serviceOrgManager")}[invite.userRole]}</td>
                        <td>{{"1":t("inviteSent"), "2":t("inviteExpired"),"3":t("signedUp")}[invite.invitationStatus]}</td>
                        <td>{moment.unix(parseFloat(invite.invitationLastSent) / 1000.).format('MMM. DD, YYYY @ HH:mm')}</td>
                        <td>{moment.unix(parseFloat(invite.expiresOn) / 1000.).format('MMM. DD, YYYY @ HH:mm')}</td>
                        <td>
                            <button onClick={async () => {
                                if (!await this.resendInvite(invite)) {
                                    alert(t('errorResendingInvite'));
                                }
                            }}>{t('resend')}</button>
                        </td>
                    </tr>))}
                </table>
			</div >
		);
	}
}


function mapStateToProps(state) {
	return {
	};
}
export default withTranslation()(withRouter(connect(mapStateToProps)(EAInvitePage)));
