import { Formik } from 'formik';
import React, { Component } from 'react';
import Loader from 'components/Loader';
import InputFormItem from 'components/FormItems/items/InputFormItem';
import SwitchFormItem from 'components/FormItems/items/SwitchFormItem';
import RadioFormItem from 'components/FormItems/items/RadioFormItem';
//import ImagesFormItem from 'components/FormItems/items/ImagesFormItem';
import usersFields from 'components/Users/usersFields';
import IniValues from 'components/FormItems/iniValues';
import PreparedValues from 'components/FormItems/preparedValues';
import FormValidations from 'components/FormItems/formValidations';
import Widget from 'components/Widget';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';

class UsersForm extends Component {
  iniValues = () => {
    return IniValues(usersFields, this.props.record || {});
  }

  formValidations = () => {
    return FormValidations(usersFields, this.props.record || {});
  }

  handleSubmit = (values) => {
    const { id, ...data } = PreparedValues(usersFields, values || {});
    this.props.onSubmit(id, data);
  };

  title = (t) => {
    if(this.props.isProfile) {
      return t('editMyAccount');
    } else {
      return t('editMyAccount');
    }
  };

  isThirdyPartyUser = () => {
    const isThirdParty = this.props.currentUser.isThirdParty ? this.props.currentUser.isThirdParty : false;
    return isThirdParty;
  }

  renderForm() {
    const {t} = this.props;
    const { saveLoading } = this.props;
    return (
      <Widget title={<h4>{this.title(t)}</h4>}>
        <Formik
          initialValues={this.iniValues()}
          validationSchema={this.formValidations()}
          onSubmit={this.handleSubmit}
          render={(form) => {
            return (
              <form onSubmit={form.handleSubmit}>

                <InputFormItem
                  name={'firstName'}
                  schema={usersFields}
                />

                <InputFormItem
                  name={'lastName'}
                  schema={usersFields}
                />

                <InputFormItem
                  name={'phoneNumber'}
                  schema={usersFields}
                />
                {console.log(this.isThirdyPartyUser())}
                { !this.isThirdyPartyUser() &&
                <div style={{"color": "#e67478", "fontStyle": "italic" }} >Warning: If you change your password you will be logged out and need to login again.</div>
                }
                { !this.isThirdyPartyUser() &&
                <InputFormItem
                  name={'oldPassword'}
                  password
                  schema={usersFields}
                />
                }

                {form.values.newPassword != form.values.confirmPassword &&
                  <><div style={{"color": "#e67478", "fontStyle": "italic"}}>{form.errors.firstName}New Password and Confirm Password are not equal</div></>
                }
                { !this.isThirdyPartyUser() &&
                <InputFormItem
                  name={'newPassword'}
                  password
                  schema={usersFields}
                  onChange={()=>{
                  }}
                />
                }
                { !this.isThirdyPartyUser() &&
                <InputFormItem
                  name={'confirmPassword'}
                  password
                  schema={usersFields}
                />
                }
                {/*
                <RadioFormItem
                  name={'role'}
                  schema={usersFields}
                />
                <SwitchFormItem
                  name={'disabled'}
                  schema={usersFields}
                />
                <ImagesFormItem
                  name={'avatar'}
                  schema={usersFields}
                  path={'users/avatar'}
                  fileProps={{
                    size: undefined,
                    formats: undefined,
                  }}
                  max={undefined}
                />*/}
                <br/>

                <div className="form-buttons">
                {  <button
                    className="btn btn-primary"
                    disabled={saveLoading || !((form.values.oldPassword == undefined && form.values.newPassword == undefined && form.values.confirmPassword == undefined)) ? !( (form.values.newPassword == form.values.confirmPassword) && form.values.oldPassword) : false}
                    type="button"
                    onClick={form.handleSubmit}
                  >
                    {t('save')}
                  </button>}{' '}{' '}

                  {/* form.handleSubmit  <button
                    className="btn btn-light"
                    type="button"
                    disabled={saveLoading}
                    onClick={form.handleReset}
                  >
                    Reset
                  </button>*/}

                    <button
                      className="btn btn-light"
                      type="button"
                      disabled={saveLoading}
                      onClick={() => this.props.onCancel()}
                    >
                      {t('cancel')}
                    </button>
                </div>
              </form>
            );
          }}
        >


        </Formik>
      </Widget>
    );
  }

  render() {
    const { isEditing, findLoading, record } = this.props;
    if (findLoading) {
      return <Loader />;
    }

    if (isEditing && !record) {
      return <Loader />;
    }

    return this.renderForm();
  }
}

export default withTranslation()(UsersForm);
