import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Hammer from "rc-hammerjs";

//import Profile from "../../pages/profile";

import RemoteAuditPage from "../../pages/remoteaudits";
import HomeownerQuestionsPage from "../../pages/homeownerquestions";
import GE360Flow from "../../pages/flow";
import GE360SubProgram from "../../pages/flow/SubProgram";
import GE360BackgroundJobs from "../../pages/flow/BackgroundJobs";
import GE360InsightsMap from "../../pages/flow/InsightsMap";
//import Products from "../../pages/products";
import EnergyAdvisor from "../../pages/energy-advisor/EnergyAdvisor";
import Buildings from "../../pages/enBuildings";
import EnergyCustomers from "../../pages/enCustomers";
import ENUserManagement from "../../pages/enUserManagement";
//import ENWD from "../../pages/enWysiwygDbg";
import InsightsOffers from "../../pages/enInsightsOffers";
import EnergyBuildingsDetails from "../../pages/enBuildingDetails/ENBuildingDetails";
import EnergyCustomerDetails from "../../pages/enCustomerDetails/ENCustomerDetails";

import EnergyBuildingsProject from "../../pages/eaBuildingDetails/EABuildingDetails";

import TermsAndConditions from "../Users/terms/TermsAndConditions";

import Header from "../Header";
import NewHeader from "../NewHeader";
import Sidebar from "../Sidebar";
import NewSidebar from "../NewSidebar";
import UserFormPage from "../Users/form/UsersFormPage";
import EAInvitePage from '../../pages/energy-advisor/EAInvitePage';
//import BreadcrumbHistory from "../BreadcrumbHistory";

import ProgramOverview from "../../pages/program-overview/ProgramOverview";

import { openSidebar, closeSidebar } from "../../actions/navigation";
import { switchAdminSite } from "../../actions/auth";

import s from "./Layout.module.scss";
import { withTranslation } from "react-i18next";

class Layout extends React.Component {
	static propTypes = {
		sidebarStatic: PropTypes.bool,
		sidebarOpened: PropTypes.bool,
		dispatch: PropTypes.func.isRequired,
		isUpdating: PropTypes.bool,
	};

	static defaultProps = {
		sidebarStatic: false,
		sidebarOpened: false,
		isUpdating: false,
	};
	constructor(props) {
		super(props);

		this.handleSwipe = this.handleSwipe.bind(this);

		this.state = { loaderActive: this.props.isUpdating };
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isUpdating !== this.props.isUpdating) {
			this.setState({ loaderActive: this.props.isUpdating });
		}
		if (prevProps.vaStatus !== this.props.vaStatus) {
			this.setState({ vaStatus: this.props.vaStatus });
		}
	}

	switchSite(newSite) {
		this.props.dispatch(switchAdminSite(newSite));
	}

	handleSwipe(e) {
		if ("ontouchstart" in window) {
			if (e.direction === 4 && !this.state.chatOpen) {
				this.props.dispatch(openSidebar());
				return;
			}

			if (e.direction === 2 && this.props.sidebarOpened) {
				this.props.dispatch(closeSidebar());
				return;
			}

			this.setState({ chatOpen: e.direction === 2 });
		}
	}

	render() {

		let app = (this.props.currentUser && this.props.currentUser.app) || "ra";
		const user = this.props.currentUser;
		const rolesContain = (...args) => {
			for (let i = 0; i < args.length; i++) {
				if (user && user.role && user.role.indexOf(args[i]) >= 0) {
					return true;
				}
			}
			return false;
		};

		let errorRedirect = "/app/main/energy-advisor";
		if (app === "en") {
			if (rolesContain("admin", "portfoliomanager", "portfolioowner")) {
				errorRedirect = "/app/main/en-dashboard";
			} else if (rolesContain("admin", "serviceprovider", "portfoliomanager", "portfolioowner", "programmanager")) {
				errorRedirect = "/app/main/en-buildings";
			}
		}

		if (!this.props.currentUser && localStorage.getItem('token')) {
			return <div/>;
		}

		if (app === "ra" || (window.location.href.indexOf("login") >= 0 && window.location.href.indexOf("en-login") < 0)) {
			document.body.style.background = `linear-gradient(269.82deg,#172336,#233149 61.08%,#172336)`;
		} else {
			document.body.style.background = `#1f2631`;
		}

		const {t} = this.props;

		return (
			<div className={[s.root, "sidebar-" + this.props.sidebarPosition, "sidebar-" + this.props.sidebarVisibility].join(" ")}>
				{this.state.loaderActive && (
					<div className={s["active-loader"]}>
						<div className={s["active-loader-inside"]}>
							<i className="la la-spinner la-spin" style={{ fontSize: "96px" }} />
						</div>
						{this.state.vaStatus !== undefined && <span className={s["active-loader-status"]}>Computing Model: {this.state.vaStatus}% Complete</span>}
					</div>
				)}
				{this.props.currentUser && this.props.currentUser.role[0] !== "customer" && <TermsAndConditions
					isCustomer={false}
					termsAccepted={this.props.currentUser && this.props.currentUser.termsAcceptedAt}
				/>}
				<div className={app === "en" ? s["wrap-en"] : s["wrap-ra"]}>
					{app === "en" && <NewHeader />}
					{app === "ra" && <Header />}
					{app === "ra" && <Sidebar />}
					{app === "en" && <NewSidebar />}
					<Hammer onSwipe={this.handleSwipe}>
						<main className={s.content}>
							<TransitionGroup>
								<CSSTransition key={this.props.location.key} classNames="fade" timeout={200}>
									<Switch>
										<Route path="/app/main" exact render={() => <Redirect to={errorRedirect} />} />
										{/*<Route path="/app/main/enwd" exact component={ENWD} />*/}
										<Route path="/app/main/energy-advisor/home/:buildingId" exact component={RemoteAuditPage} />
										{/*<Route path="/app/main/flow" exact component={GE360Flow} />*/}
										<Route path="/app/main/en-etl-flows" exact component={GE360Flow} />
										<Route path="/app/main/sub-flow" exact component={GE360SubProgram} />
										<Route path="/app/main/en-bg-flows" exact component={GE360BackgroundJobs} />
										<Route path="/app/main/en-insights-map" exact component={GE360InsightsMap} />
										<Route path="/app/main/en-buildings" exact component={Buildings} />
										<Route path="/app/main/en-customers" exact component={EnergyCustomers} />
										<Route path="/app/main/en-user-management" exact component={ENUserManagement} />
										<Route path="/app/main/en-dashboard" exact component={InsightsOffers} />
										<Route path="/admin" exact render={() => <Redirect to="/admin/users" />} />
										{rolesContain('programmanager') ? <Route path="/app/main/program-overview" exact component={ProgramOverview} /> : undefined}
										<Route path="/app/main/energy-advisor" exact component={EnergyAdvisor} />
										<Route path="/app/main/building-details/:buildingId" exact component={EnergyBuildingsDetails} />
										<Route path="/app/main/customer-details/:customerId" exact component={EnergyCustomerDetails} />
										<Route path="/app/main/energy-buildings-project/:buildingId" exact component={EnergyBuildingsProject} />
										<Route path="/app/main/homeowner-questions" exact component={HomeownerQuestionsPage} />
										<Route path="/app/main/account" exact component={UserFormPage} />
										<Route path="/app/main/invites" exact component={EAInvitePage} />
									</Switch>
								</CSSTransition>
							</TransitionGroup>
							<footer className={s.contentFooter}>
								{window.location.href.indexOf("energy-navigator") >= 0 ? t('energyNavigatorApplicationBy') : t('energyAdvisorApplicationBy')} {" "}
								<a href="https://www.climative.ai/">Climative</a>
								{window._HAS_BOTH_SITES && (
									<span style={{ marginLeft: "32px" }}>
										Switch to{" "}
										<a
											href="#"
											onClick={(event) => {
												this.switchSite(app === "ra" ? "en" : "ra");
												(event || window.event).preventDefault();
												return false;
											}}
										>
											Energy {app === "ra" ? "Navigator" : "Advisor"}
										</a>
									</span>
								)}
							</footer>
						</main>
					</Hammer>
				</div>
			</div>
		);
	}
}

function mapStateToProps(store) {
	return {
		sidebarOpened: store.navigation.sidebarOpened,
		sidebarPosition: store.navigation.sidebarPosition,
		sidebarVisibility: store.navigation.sidebarVisibility,
		isUpdating: store.buildings.isUpdating,
		vaStatus: store.buildings.vaStatus,
		currentUser: store.auth.currentUser,
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(Layout)));
