/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, FormGroup, Label, Input, Collapse } from 'reactstrap';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getBuildingRequest, getBuildingRequestLM, buildingWizardRequest } from '../../../../actions/buildings';
import './UpgradeScenariosPanel.scss';
import s from './UpgradeScenariosPanel.module.scss';
import Select from 'react-select';
import { listsData } from "../../selectLists";
import RValueWidget from "../postupgradepanel/RValueWidget";
import HPDetails from '../heatpump-details/HPDetails';
import HSUpgrade from '../heating-sys-upgrade/HSUpgrade';
import { withTranslation } from 'react-i18next';
import SelectWithTranslations from '../../../../components/SelectWithTranslation';
import HEInput from '../heating-eff-input/HEInput';

class UpgradeScenariosPanel extends React.Component {

  static propTypes = {
    model: PropTypes.object,
    building: PropTypes.object,
    isUpdating: PropTypes.bool
  };

  static defaultProps = {
    model: {},
    building: {}
  };

  constructor(props) {
    super(props);

    this.handleUpgradeSubmit = this.handleUpgradeSubmit.bind(this);

    // cop = hspf * 1055.1 / 3600.0
    // hspf = (cop * 3600.) / 1055.1
    // watts = btu * 0.293071
    // btu = watts / 0.293071

    this.hvacKey = 'hvk-' + Math.random();
    this.windoorKey = 'wdk-' + Math.random();
    
    this.lastDSCTimer = -1;

    let rebateObj = JSON.parse(props.building.selected_rebates_json || '{}');

    this.state = {
      primaryHotWaterEqPostUpgradeType: listsData.list.primaryHotWaterEqPostUpgradeType,
      primaryHotWaterEqFuelType: listsData.list.primaryHotWaterEqFuelType,
      primaryHotWaterEquipmentType: props.building.primaryHotWaterEquipmentType,
      primaryHotWaterEquipmentFuelType: props.building.primaryHotWaterEquipmentFuelType,
      primaryHotWaterHeaterEfficiency: props.building.primaryHotWaterHeaterEfficiency,
      totalNumberOfInstalledEStarWindows: props.building.totalNumberOfInstalledEStarWindowsPostUpgrade,
      totalNumberOfInstalledEStarDoors: props.building.totalNumberOfInstalledEStarDoorsPostUpgrade,
      preTotalNumberOfInstalledEStarWindows: props.building.totalNumberOfInstalledEStarWindows,
      preTotalNumberOfInstalledEStarDoors: props.building.totalNumberOfInstalledEStarDoors,
      heatPumpCOP: window.GET_FF('eaf-hspf-cop-inputs-flag') ? Math.round(props.building.heatingCOP*1000)/1000 : Math.round((props.building.heatingCOP * 3600.) / 1055.1*1000)/1000,
      heatPumpHSPF: Math.round(props.building.heatingHSPF*1000)/1000,
      heatPumpHPCAP: Math.round((props.building.heatPumpHPCAP) / 0.293071),

      hasDucting: !!rebateObj.has_ducting,
      ohpaRebate: !!rebateObj.efficiency_ns_ohpa,
      moderateIncomeRebate: !!rebateObj.efficiency_ns_moderate_income,

      mainWallInsulationEffectiveDef: props.building.mainWallInsulationEffectiveDef,
      mainWallInsulationNominalDef: props.building.mainWallInsulationNominalDef,
      ceilingInsulationEffectiveDef: props.building.ceilingInsulationEffectiveDef,
      ceilingInsulationNominalDef: props.building.ceilingInsulationNominalDef,
      ceilingInsulationTypesDef: props.building.ceilingInsulationTypesDef,
      foundationWallInsulationEffectiveDef: props.building.foundationWallInsulationEffectiveDef,
      foundationWallInsulationNominalDef: props.building.foundationWallInsulationNominalDef,
      foundationWallInsulationTypesDef: props.building.foundationWallInsulationTypesDef,
      floorAboveCrawlspaceInsulationEffectiveDef: props.building.floorAboveCrawlspaceInsulationEffectiveDef,
      floorAboveCrawlspaceInsulationNominalDef: props.building.floorAboveCrawlspaceInsulationNominalDef,
      
      mainWallInsulationEffectiveDefPostUpgrade: props.building.mainWallInsulationEffectiveDefPostUpgrade,
      ceilingInsulationEffectiveDefPostUpgrade: props.building.ceilingInsulationEffectiveDefPostUpgrade,
      foundationWallInsulationEffectiveDefPostUpgrade: props.building.foundationWallInsulationEffectiveDefPostUpgrade,
      floorAboveCrawlspaceInsulationEffectiveDefPostUpgrade: props.building.floorAboveCrawlspaceInsulationEffectiveDefPostUpgrade,

      mainWallInsulationUpgradesJSON: props.building.mainWallInsulationUpgradesJSON,
      ceilingInsulationUpgradesJSON: props.building.ceilingInsulationUpgradesJSON,
      foundationWallInsulationUpgradesJSON: props.building.foundationWallInsulationUpgradesJSON,
      floorAboveCrawlspaceUpgradesJSON: props.building.floorAboveCrawlspaceUpgradesJSON,

      mainWallInsulationNominalDefPostUpgrade: props.building.mainWallInsulationNominalDefPostUpgrade,
      ceilingInsulationNominalDefPostUpgrade: props.building.ceilingInsulationNominalDefPostUpgrade,
      foundationWallInsulationNominalDefPostUpgrade: props.building.foundationWallInsulationNominalDefPostUpgrade,
      floorAboveCrawlspaceInsulationNominalDefPostUpgrade: props.building.floorAboveCrawlspaceInsulationNominalDefPostUpgrade,

      ceilingInsulationTypesDefPostUpgrade: props.building.ceilingInsulationTypesDefPostUpgrade,
      foundationWallInsulationTypesDefPostUpgrade: props.building.foundationWallInsulationTypesDefPostUpgrade,

      // convert boolean + 'mshp' to 'mshp'/'mshp-ductless' or other
      heatPumpUpgradeType: (!rebateObj.has_ducting && props.building.heatPumpUpgradeType === 'mshp') ? 'mshp-ductless' : props.building.heatPumpUpgradeType,
      heatPumpUpgradeEstRebate: props.building.heatPumpUpgradeEstRebate,
      heatPumpElectricalServiceSize: props.building.heatPumpElectricalServiceSize,
      winDoorUpgradeEstRebate: props.building.winDoorUpgradeEstRebate

    };

    props.building.heatPumpHPCAPPostUpgrade = this.state.heatPumpHPCAP;

    let primaryHotWaterEqPostUpgradeTypeItem = listsData.list.primaryHotWaterEqPostUpgradeType.filter(function (x) { return x.value === props.building.primaryHotWaterEquipmentType; });
    props.building.primaryHotWaterEquipmentTypeSelected = primaryHotWaterEqPostUpgradeTypeItem;
    this.state.primaryHotWaterEquipmentTypeSelected = primaryHotWaterEqPostUpgradeTypeItem;

    let hpUpgradeType = this.state.heatPumpUpgradeType
    let heatPumpUpgradeTypeItem = listsData.list.heatPumpUpgradeTypes.filter((x) => x.value === hpUpgradeType);
    props.building.heatPumpUpgradeTypeSelected = heatPumpUpgradeTypeItem;
    this.state.heatPumpUpgradeTypeSelected = heatPumpUpgradeTypeItem;

    let primaryHotWaterEqFuelType = listsData.list.primaryHotWaterEqFuelType.filter(function (x) { return x.value === props.building.primaryHotWaterEquipmentFuelType; });
    props.building.primaryHotWaterEquipmentFuelTypeSelected = primaryHotWaterEqFuelType;
    this.state.primaryHotWaterEquipmentFuelTypeSelected = primaryHotWaterEqFuelType;

    this.toggleAccordionFirst = this.toggleAccordionFirst.bind(this);

    this.defaultAccordionCheck = JSON.parse(props.building.upgradeScenariosChecked || '[]');
    if (!this.defaultAccordionCheck.length) {
      this.defaultAccordionCheck = null;
    }

    this.lastModelStr = JSON.stringify({});

    this.delayWaiting = 0;

    this.changeTOs = {};
  }

  componentDidMount() {
    const buildingId = this.props.match.params.buildingId;

    this.props.dispatch(getBuildingRequest(buildingId));
    this.props.dispatch(getBuildingRequestLM(buildingId));
  }

  delayStateChange(time) {
    if (this.lastDSCTimer != -1) {
      clearTimeout(this.lastDSCTimer);
      this.lastDSCTimer = -1;
    }
    this.lastDSCTimer = setTimeout(() => {
      this.lastDSCTimer = -1;
      if (this.onStateChange) {
        this.onStateChange(this.getBuildingInputs());
      }
    }, time ?? 250);
  }

  getBuildingInputs() {
    let selected_rebates_json = {};

    // convert 'mshp' (ducted) and 'mshp-ductless' to 'mshp' + ducting boolean for saving
    let hpType = this.state.heatPumpUpgradeType;
    if ('mshp' === hpType) {
      selected_rebates_json.has_ducting = true;
      selected_rebates_json.efficiency_ns_moderate_income = false;
    }
    else if ('mshp-ductless' === hpType) {
      hpType = 'mshp';
      selected_rebates_json.has_ducting = false;
    }
    ///

    if (this.state.ohpaRebate) {
      selected_rebates_json.efficiency_ns_ohpa = true;
    }
    if (this.state.moderateIncomeRebate && !selected_rebates_json.has_ducting) {
      selected_rebates_json.efficiency_ns_moderate_income = true;
    }
    return {
      buildingId: this.props.building.buildingId,
      heatingCOP: window.GET_FF('eaf-hspf-cop-inputs-flag') ? (this.state.accordionCheckbox[0] ? this.state.heatPumpCOP : null) : (this.state.accordionCheckbox[0] ? (this.state.heatPumpCOP * 1055.1 / 3600.0) : null),
      heatingHSPF: this.state.accordionCheckbox[0] ? this.state.heatPumpHSPF : null,
      heatPumpHPCAP: this.state.accordionCheckbox[0] ? (this.state.heatPumpHPCAP * 0.293071) : null,
      heatPumpUpgradeType: this.state.accordionCheckbox[0] ? hpType : null,
      heatPumpUpgradeEstRebate: this.state.accordionCheckbox[0] ? (this.state.heatPumpUpgradeEstRebate||0) : null,
      heatPumpElectricalServiceSize: this.state.accordionCheckbox[0] ? (this.state.heatPumpElectricalServiceSize||0) : null,
      primaryHotWaterEquipmentType: this.state.accordionCheckbox[1] ? this.state.primaryHotWaterEquipmentType : null,
      //primaryHotWaterEquipmentFuelType: this.state.accordionCheckbox[1] ? this.state.primaryHotWaterEquipmentFuelType : null,
      primaryHotWaterHeaterEfficiency: this.state.accordionCheckbox[1] ? this.state.primaryHotWaterHeaterEfficiency : undefined,
      totalNumberOfInstalledEStarWindowsPostUpgrade: this.state.accordionCheckbox[5] ? this.state.totalNumberOfInstalledEStarWindows : null,
      totalNumberOfInstalledEStarDoorsPostUpgrade: this.state.accordionCheckbox[5] ? this.state.totalNumberOfInstalledEStarDoors : null,
      winDoorUpgradeEstRebate: this.state.accordionCheckbox[5] ? (this.state.winDoorUpgradeEstRebate||0) : null,
 
      mainWallInsulationEffectiveDefPostUpgrade: this.state.accordionCheckbox[2] ? this.state.mainWallInsulationEffectiveDefPostUpgrade : null,
      ceilingInsulationEffectiveDefPostUpgrade: this.state.accordionCheckbox[3] ? this.state.ceilingInsulationEffectiveDefPostUpgrade : null,
      foundationWallInsulationEffectiveDefPostUpgrade: this.state.accordionCheckbox[4] ? this.state.foundationWallInsulationEffectiveDefPostUpgrade : null,
      floorAboveCrawlspaceInsulationEffectiveDefPostUpgrade: this.state.accordionCheckbox[4] ? this.state.floorAboveCrawlspaceInsulationEffectiveDefPostUpgrade : null,

      mainWallInsulationNominalDefPostUpgrade: this.state.accordionCheckbox[2] ? this.state.mainWallInsulationNominalDefPostUpgrade : null,
      ceilingInsulationNominalDefPostUpgrade: this.state.accordionCheckbox[3] ? this.state.ceilingInsulationNominalDefPostUpgrade : null,
      foundationWallInsulationNominalDefPostUpgrade: this.state.accordionCheckbox[4] ? this.state.foundationWallInsulationNominalDefPostUpgrade : null,
      floorAboveCrawlspaceInsulationNominalDefPostUpgrade: this.state.accordionCheckbox[4] ? this.state.floorAboveCrawlspaceInsulationNominalDefPostUpgrade : null,

      mainWallInsulationUpgradesJSON: this.state.accordionCheckbox[2] ? this.state.mainWallInsulationUpgradesJSON : null,
      ceilingInsulationUpgradesJSON: this.state.accordionCheckbox[3] ? this.state.ceilingInsulationUpgradesJSON : null,
      foundationWallInsulationUpgradesJSON: this.state.accordionCheckbox[4] ? this.state.foundationWallInsulationUpgradesJSON : null,
      floorAboveCrawlspaceUpgradesJSON: this.state.accordionCheckbox[4] ? this.state.floorAboveCrawlspaceUpgradesJSON : null,

      ceilingInsulationTypesDefPostUpgrade: this.state.accordionCheckbox[3] ? this.state.ceilingInsulationTypesDefPostUpgrade : null,
      foundationWallInsulationTypesDefPostUpgrade: this.state.accordionCheckbox[4] ? this.state.foundationWallInsulationTypesDefPostUpgrade : null,

      upgradeScenariosChecked: JSON.stringify(this.state.accordionCheckbox),
      run_model_type: 'post-upgrade',

      hp_details_json: this.props.building.hp_details_json,
      hs_upgrade_json: this.props.building.hs_upgrade_json,
      selected_rebates_json: JSON.stringify(selected_rebates_json)
    };
  } 

  handleUpgradeSubmit(event) {
    // cop = hspf * 1055.1 / 3600.0
    // hspf = (cop * 3600.) / 1055.1
    // watts = btu * 0.293071
    // btu = watts / 0.293071

    if (this.lastDSCTimer != -1 || this.delayWaiting > 0) {
      return;
    }

    this.buildingInputs = this.getBuildingInputs();

    if (!this.state.accordionCheckbox[0]) {
      this.setState({
        heatPumpCOP: null,
        heatPumpHPCAP: null,
        heatPumpUpgradeType: null,
        heatPumpUpgradeTypeSelected: null,
        heatPumpUpgradeEstRebate: null,
        heatPumpElectricalServiceSize: null
      });
      this.hvacKey = 'hvk-' + Math.random();
    }

    if (!this.state.accordionCheckbox[5]) {
      this.setState({
        totalNumberOfInstalledEStarWindowsPostUpgrade: null,
        totalNumberOfInstalledEStarDoorsPostUpgrade: null
      });
      this.windoorKey = 'wdk-' + Math.random();
    }

    if (this.props.onStateChange) {
      this.props.onStateChange(this.buildingInputs, true);
    }

    this.props.dispatch(buildingWizardRequest({buildingId: this.props.building.buildingId }, 'refresh', this.buildingInputs));

    event.preventDefault();
    
  }

  foundationWidgetChange(strREff, strRNom, strTypes, jsonUpgrades) {
    this.props.building.foundationWallInsulationEffectiveDefPostUpgrade = strREff;
    this.setState({ foundationWallInsulationEffectiveDefPostUpgrade: strREff });
    this.props.building.foundationWallInsulationNominalDefPostUpgrade = strRNom;
    this.setState({ foundationWallInsulationNominalDefPostUpgrade: strRNom });
    this.props.building.foundationWallInsulationTypesDefPostUpgrade = strTypes;
    this.setState({ foundationWallInsulationTypesDefPostUpgrade: strTypes });
    this.props.building.foundationWallInsulationUpgradesJSON = jsonUpgrades;
    this.setState({ foundationWallInsulationUpgradesJSON: jsonUpgrades });
    this.delayStateChange();
  }

  floorAboveCrawlspaceWidgetChange(strREff, strRNom, strTypes, jsonUpgrades) {
    this.props.building.floorAboveCrawlspaceInsulationEffectiveDefPostUpgrade = strREff;
    this.setState({ floorAboveCrawlspaceInsulationEffectiveDefPostUpgrade: strREff });
    this.props.building.floorAboveCrawlspaceInsulationNominalDefPostUpgrade = strRNom;
    this.setState({ floorAboveCrawlspaceInsulationNominalDefPostUpgrade: strRNom });
    this.props.building.floorAboveCrawlspaceUpgradesJSON = jsonUpgrades;
    this.setState({ floorAboveCrawlspaceUpgradesJSON: jsonUpgrades });
    this.delayStateChange();
  }

  wallsWidgetChange(strREff, strRNom, strTypes, jsonUpgrades) {
    this.props.building.mainWallInsulationEffectiveDefPostUpgrade = strREff;
    this.setState({ mainWallInsulationEffectiveDefPostUpgrade: strREff });
    this.props.building.mainWallInsulationNominalDefPostUpgrade = strRNom;
    this.setState({ mainWallInsulationNominalDefPostUpgrade: strRNom });
    this.props.building.mainWallInsulationUpgradesJSON = jsonUpgrades;
    this.setState({ mainWallInsulationUpgradesJSON: jsonUpgrades });
    this.delayStateChange();
  }

  ceilingWidgetChange(strREff, strRNom, strTypes, jsonUpgrades) {
    this.props.building.ceilingInsulationEffectiveDefPostUpgrade = strREff;
    this.setState({ ceilingInsulationEffectiveDefPostUpgrade: strREff });
    this.props.building.ceilingInsulationNominalDefPostUpgrade = strRNom;
    this.setState({ ceilingInsulationNominalDefPostUpgrade: strRNom });
    this.props.building.ceilingInsulationTypesDefPostUpgrade = strTypes;
    this.setState({ ceilingInsulationTypesDefPostUpgrade: strTypes });
    this.props.building.ceilingInsulationUpgradesJSON = jsonUpgrades;
    this.setState({ ceilingInsulationUpgradesJSON: jsonUpgrades });
    this.delayStateChange();
  }

  foundationGetRebate(baseREff, baseRNom, baseType, baseSQFT, upREff, upRNom, upSQFT) {
    let numRebates = upSQFT / 100;
    let nomAdded = upRNom - baseRNom;
    if (nomAdded >= 10 && nomAdded < 20) {
      return 40 * numRebates;
    }
    else if (nomAdded >= 20) {
      return 80 * numRebates;
    }
    return 0;
  }

  floorAboveCrawlspaceGetRebate(baseREff, baseRNom, baseType, baseSQFT, upREff, upRNom, upSQFT) {
    let numRebates = upSQFT / 100;
    let nomAdded = upRNom - baseRNom;
    if (nomAdded >= 10 && nomAdded < 20) {
      return 40 * numRebates;
    }
    else if (nomAdded >= 20) {
      return 80 * numRebates;
    }
    return 0;
  }

  wallsGetRebate(baseREff, baseRNom, baseType, baseSQFT, upREff, upRNom, upSQFT) {
    let numRebates = upSQFT / 100;
    let nomAdded = upRNom - baseRNom;
    if (nomAdded >= 5 && nomAdded < 10) {
      return 70 * numRebates;
    }
    else if (nomAdded >= 10) {
      return 90 * numRebates;
    }
    return 0;
  }

  ceilingGetRebate(baseREff, baseRNom, baseType, baseSQFT, upREff, upRNom, upSQFT) {
    let numRebates = upSQFT / 100;
    let nomAdded = upRNom - baseRNom;
    if (baseType === 'T') {
      if (nomAdded >= 20 && nomAdded < 40) {
        return 30 * numRebates;
      }
      else if (nomAdded >= 40) {
        return 60 * numRebates;
      }
    }
    else {
      if (nomAdded >= 12) {
        return 60 * numRebates;
      }
    }
    return 0;
  }

  updateHPEstRebate() {
    let tons = this.state.heatPumpHPCAP / 12000;
    let ret = 0;
    if (this.state.heatPumpUpgradeType === 'mshp') {
      ret = 300 * tons;
    }
    else if (this.state.heatPumpUpgradeType === 'central') {
      ret = 500 * tons;
    }
    else if (this.state.heatPumpUpgradeType === 'air-water') {
      ret = 500 * tons;
    }
    else if (this.state.heatPumpUpgradeType === 'geo') {
      ret = 500 * tons;
    }
    this.setState({heatPumpUpgradeEstRebate: ret});
    this.delayStateChange();
  }

  updateWinDoorEstRebate() {
    let ret = 0;
    ret += ((this.props.building.totalNumberOfInstalledEStarWindowsPostUpgrade||0) - (this.props.building.totalNumberOfInstalledEStarWindows || 0)) * 30;
    ret += ((this.props.building.totalNumberOfInstalledEStarDoorsPostUpgrade||0) - (this.props.building.totalNumberOfInstalledEStarDoors || 0)) * 30;
    ret = Math.max(ret, 0);
    this.setState({winDoorUpgradeEstRebate: ret});
    this.delayStateChange();
  }

  wallsErrorChange(err) {
    this.setState({ errorWalls: !!err });
    // this.state.accordionCheckbox[2]
    this.delayStateChange();
  }

  ceilingErrorChange(err) {
    this.setState({ errorCeiling: !!err });
    // this.state.accordionCheckbox[3]
    this.delayStateChange();
  }

  foundationErrorChange(err) {
    this.setState({ errorFoundation: !!err });
    // this.state.accordionCheckbox[4]
    this.delayStateChange();
  }

  handleChange(e, parent) {
    if (!parent) {
      parent = e.target;
    }

    this.delayStateChange();

    const delayIfNeeded = (name, cbk) => {
      this.delayWaiting += 1;
      if (window.event instanceof InputEvent) {
        if (this.changeTOs[name] !== undefined) {
          this.delayWaiting -= 1;
          window.clearTimeout(this.changeTOs[name]);
        }
        this.changeTOs[name] = setTimeout(() => {
          this.delayWaiting -= 1;
          this.changeTOs[name] = undefined;
          cbk();
        }, 750);
      }
      else {
        this.delayWaiting -= 1;
        if (this.changeTOs[name] !== undefined) {
          window.clearTimeout(this.changeTOs[name]);
          this.changeTOs[name] = undefined;
        }
        cbk();
      }
    };

    switch (parent.name) {
      case "primaryHotWaterEquipmentType":
        var primaryHotWaterEqPostUpgradeTypeItem = listsData.list.primaryHotWaterEqPostUpgradeType.filter(function (x) { return x.value === e.value; });
        this.props.building.primaryHotWaterEquipmentTypeSelected = primaryHotWaterEqPostUpgradeTypeItem;
        this.setState({ primaryHotWaterEquipmentType: e.value, primaryHotWaterEquipmentTypeSelected: primaryHotWaterEqPostUpgradeTypeItem });
        this.props.onStateChange(this.getBuildingInputs());
        break;
      case "primaryHotWaterEquipmentFuelType":
        var primaryHotWaterEqFuelTypeItem = listsData.list.primaryHotWaterEqFuelType.filter(function (x) { return x.value === e.value; });
        this.props.building.primaryHotWaterEquipmentFuelTypeSelected = primaryHotWaterEqFuelTypeItem;
        this.setState({ primaryHotWaterEquipmentFuelType: e.value, primaryHotWaterEquipmentFuelTypeSelected: primaryHotWaterEqFuelTypeItem });
        this.props.onStateChange(this.getBuildingInputs());
        break;
      case "primaryHotWaterHeaterEfficiency":
        this.setState({ primaryHotWaterHeaterEfficiency: parent.value });
        this.props.building.primaryHotWaterHeaterEfficiency = parent.value;
        this.props.onStateChange(this.getBuildingInputs());
        break;
      case "totalNumberOfInstalledEStarWindows":
        delayIfNeeded(parent.name, () => {
          if (parent.value || parent.value === 0) {
            parent.value = Math.max(0, Math.min(this.props.building.totalNumberOfInstalledWindows || 100, parent.value));
          }
          this.setState({ totalNumberOfInstalledEStarWindows: parent.value });
          this.props.building.totalNumberOfInstalledEStarWindowsPostUpgrade = parent.value;
          if (!parent.value && parent.value !== 0) {
            this.setState({ errorEStarWindows: true });
          }
          else {
            this.setState({ errorEStarWindows: false });
          }
          setTimeout(() => {this.updateWinDoorEstRebate();}, 100);
        });
        break;
      case "totalNumberOfInstalledEStarDoors":
        delayIfNeeded(parent.name, () => {
          if (parent.value || parent.value === 0) {
            parent.value = Math.max(0, Math.min(this.props.building.totalNumberOfInstalledDoors || 50, parent.value));
          }
          this.setState({ totalNumberOfInstalledEStarDoors: parent.value });
          this.props.building.totalNumberOfInstalledEStarDoorsPostUpgrade = parent.value;
          if (!parent.value && parent.value !== 0) {
            this.setState({ errorEStarDoors: true });
          }
          else {
            this.setState({ errorEStarDoors: false });
          }
          setTimeout(() => {this.updateWinDoorEstRebate();}, 100);
        });
        break;
      case "heatPumpUpgradeType":
        var heatPumpUpgradeTypeItem = listsData.list.heatPumpUpgradeTypes.filter(function (x) { return x.value === e.value; });
        this.props.building.heatPumpUpgradeType = e.value;
        this.setState({ heatPumpUpgradeType: e.value, heatPumpUpgradeTypeSelected: heatPumpUpgradeTypeItem });
        if (!e.value || e.value === 'Select') {
          this.setState({ errorHeatPumpType: true });
        }
        else {
          this.setState({ errorHeatPumpType: false });
        }
        setTimeout(() => {this.updateHPEstRebate();}, 100);
        break;
      case "heatPumpCOP":
        delayIfNeeded(parent.name, () => {
          if (parent.value < 0) {
            parent.value = 0;
          }
          if (parent.value > 100) {
            parent.value = 100;
          }
          this.setState({ heatPumpCOP: parent.value });
          this.props.building.heatPumpCOP = parent.value;
          if (!(parent.value >= 1)) {
            this.setState({ errorHeatPumpCOP: true });
          }
          else {
            this.setState({ errorHeatPumpCOP: false });
          }
          this.props.onStateChange(this.getBuildingInputs());
        });
        break;
      case "heatPumpHPCAP":
        delayIfNeeded(parent.name, () => {
          if (parent.value < 0) {
            parent.value = 0;
          }
          if (parent.value > 1000000) {
            parent.value = 1000000;
          }
          this.setState({ heatPumpHPCAP: parent.value });
          this.props.building.heatPumpHPCAPPostUpgrade = parent.value;
          if (!(parent.value > 0)) {
            this.setState({ errorHeatPumpHPCAP: true });
          }
          else {
            this.setState({ errorHeatPumpHPCAP: false });
          }
          setTimeout(() => {this.updateHPEstRebate(); }, 100);
        });
        break;
      case "heatPumpElectricalServiceSize":
        delayIfNeeded(parent.name, () => {
          if (parent.value < 0) {
            parent.value = 0;
          }
          if (parent.value > 1000) {
            parent.value = 1000;
          }
          this.setState({ heatPumpElectricalServiceSize: parent.value });
          this.props.building.heatPumpElectricalServiceSize = parent.value;
          /*if (!(parent.value > 0)) {
            this.setState({ errorHeatPumpESS: true });
          }
          else {
            this.setState({ errorHeatPumpESS: false });
          }*/
          this.props.onStateChange(this.getBuildingInputs());
        });
        break;
      default:
        this.setState({ unknowDropDownValue: e.value });
        this.props.onStateChange(this.getBuildingInputs());
    }
  }

  headerTitleError(title) {
    let error = false;
    if (title == "Wall Insulation") {
      error = this.state.accordionCheckbox[2] && this.state.errorWalls;
    }
    else if (title == "Ceiling Insulation") {
      error = this.state.accordionCheckbox[3] && this.state.errorCeiling;
    }
    else if (title == "Foundation Insulation") {
      error = this.state.accordionCheckbox[4] && this.state.errorFoundation;
    }
    else if (title == "Domestic Hot Water") {

    }
    else if (title == "Heat Pump") {
      error = this.state.accordionCheckbox[0] && (this.state.errorHeatPumpESS || this.state.errorHeatPumpHPCAP || this.state.errorHeatPumpCOP || this.state.errorHeatPumpType);
    }
    else if (title == "Doors And Windows") {
      error = this.state.accordionCheckbox[5] && (this.state.errorEStarDoors || this.state.errorEStarWindows || (!parseFloat(this.state.totalNumberOfInstalledEStarDoors) && !parseFloat(this.state.totalNumberOfInstalledEStarWindows)));
    }
    return error;
  }

  renderAccordionInner(title) {

    const {t} = this.props;

    let B = this.props?.model?.LPRE?.property ?? {};

    if (title == "Wall Insulation") {
      return (
        <Col xs={12}>
          <FormGroup row>
            <Col xs={12}>
              <RValueWidget
                titleType={t('wallInsulation')}
                defaultStrREff={this.state.mainWallInsulationEffectiveDef}
                defaultStrRNom={this.state.mainWallInsulationNominalDef}
                defaultJSONUpgrades={this.state.mainWallInsulationUpgradesJSON}
                sectionNames={this.props.building.mainWallInsulationSectionNames}
                onChange={this.wallsWidgetChange.bind(this)}
                preUpgradeWarningThresholdRSI={4.5 * 0.8}
                postUpgradeWarningThresholdRSI={4.5}
                baseMaterial='wood'
                totalArea={this.props.building.wallsArea || B.walls_area_sqft}
                isUpgrade={true}
                calcEstRebateFn={this.wallsGetRebate.bind(this)}
                onErrorChange={this.wallsErrorChange.bind(this)}
                error={this.state.errorWalls && this.state.accordionCheckbox[2]}
                compositionOptions={listsData.list.wallInsulationPresets}
                noErrorDot={true}
              />
              <br />
            </Col>
          </FormGroup>
        </Col>
      );
    }
    else if (title == "Ceiling Insulation") {
      return (
        <Col xs={12}>
          <FormGroup row>
            <Col xs={12}>
              <RValueWidget
                titleType={t('ceilingAtticInsulation')}
                defaultStrREff={this.state.ceilingInsulationEffectiveDef}
                defaultStrRNom={this.state.ceilingInsulationNominalDef}
                defaultStrTypes={this.state.ceilingInsulationTypesDef}
                defaultJSONUpgrades={this.state.ceilingInsulationUpgradesJSON}
                sectionNames={this.props.building.ceilingInsulationSectionNames}
                preUpgradeWarningThresholdRSI={10.5 * 0.8}
                postUpgradeWarningThresholdRSI={10.5}
                onChange={this.ceilingWidgetChange.bind(this)}
                insulationTypes={listsData.list.ceilingInsulationTypes}
                baseMaterial='wood'
                totalArea={this.props.building.ceilingArea || B.ceiling_area_sqft}
                isUpgrade={true}
                calcEstRebateFn={this.ceilingGetRebate.bind(this)}
                onErrorChange={this.ceilingErrorChange.bind(this)}
                error={this.state.errorCeiling && this.state.accordionCheckbox[3]}
                compositionOptions={listsData.list.ceilingInsulationPresets}
                noErrorDot={true}
              />
              <br />
            </Col>
          </FormGroup>
        </Col>
      );
    }
    else if (title == "Foundation Insulation") {
      return (
        <Col xs={12}>
          <FormGroup row>
            <Col xs={12}>
              <RValueWidget
                titleType={t('foundationInsulation')}
                defaultStrREff={this.state.foundationWallInsulationEffectiveDef}
                defaultStrRNom={this.state.foundationWallInsulationNominalDef}
                defaultStrTypes={this.state.foundationWallInsulationTypesDef}
                defaultJSONUpgrades={this.state.foundationWallInsulationUpgradesJSON}
                sectionNames={this.props.building.foundationWallInsulationSectionNames}
                onChange={this.foundationWidgetChange.bind(this)}
                insulationTypes={listsData.list.foundationMainInsulationTypes}
                preUpgradeWarningThresholdRSI={5 * 0.8}
                postUpgradeWarningThresholdRSI={5}
                baseMaterial='concrete'
                totalArea={this.props.building.foundationWallArea || B.foundation_wall_area_sqft || (B.walls_area_sqft / B.storeys)}
                isUpgrade={true}
                calcEstRebateFn={this.foundationGetRebate.bind(this)}
                onErrorChange={this.foundationErrorChange.bind(this)}
                error={this.state.errorFoundation && this.state.accordionCheckbox[4]}
                compositionOptions={listsData.list.foundationInsulationPresets}
                noErrorDot={true}
              />
              <br />
              {false && this.state.floorAboveCrawlspaceInsulationEffectiveDef && <RValueWidget
                titleType={t('floorAboveCrawlSpace')}
                defaultStrREff={this.state.floorAboveCrawlspaceInsulationEffectiveDef}
                defaultStrRNom={this.state.floorAboveCrawlspaceInsulationNominalDef}
                sectionNames={this.props.building.floorAboveCrawlspaceInsulationSectionNames}
                preUpgradeWarningThresholdRSI={5 * 0.8}
                onChange={this.floorAboveCrawlspaceWidgetChange.bind(this)}
                noRebate={true}
                baseMaterial='concrete'
                totalArea={this.props.building.crawlSpaceFloorArea || B.crawlspace_floor_area_sqft || (B.total_floor_area_sqft / B.storeys)}
                isUpgrade={true}
                compositionOptions={listsData.list.floorAboveCrawlspaceInsulationPresets}
                noErrorDot={true}
                partial={true}
              />}
              <br />
            </Col>
          </FormGroup>
        </Col>
      );
    }
    else if (title == "Domestic Hot Water") {
      return (
        <Col xs={12}>
          <FormGroup row>
            <Col xs={12}>
              <Label for="normal-field">{t('primaryHotWaterEquipmentType')}</Label>
            </Col>
            <Col xs={12} className={s.select2}>
              <SelectWithTranslations
                classNamePrefix="react-select"
                className="selectCustomization"
                options={this.state.primaryHotWaterEqPostUpgradeType}
                value={this.state.primaryHotWaterEquipmentTypeSelected}
                name='primaryHotWaterEquipmentType'
                onChange={this.handleChange.bind(this)}
              />
              <br />
            </Col>
            {/*<Col xs={12}>
              <Label for="normal-field">Primary Hot Water Equipment Fuel</Label>
            </Col>

            <Col xs={12} className={s.select2}>
              <Select
                classNamePrefix="react-select"
                className="selectCustomization"
                options={this.state.primaryHotWaterEqFuelType}
                value={this.state.primaryHotWaterEquipmentFuelTypeSelected}
                name='primaryHotWaterEquipmentFuelType'
                onChange={this.handleChange.bind(this)}
              />
              <br />
            </Col>*/}
            <Col xs={12}>
              <Label for="normal-field">{t('primaryHotWaterHeaterEfficiencyFactor')}</Label>
            </Col>
            <Col xs={12}>
              <Input type="number" defaultValue={this.state.primaryHotWaterHeaterEfficiency} name='primaryHotWaterHeaterEfficiency' onChange={this.handleChange.bind(this)} />
              <br />
            </Col>
          </FormGroup>
        </Col>
      );
    }
    else if (title == "Heat Pump") {
      return (
        <Col xs={12} key={this.hvacKey}>
          <FormGroup row>
            <Col xs={12}>
              <Label for="heatPumpUpgradeType"><span className={this.state.errorHeatPumpType ? "flabel-error" : ""}>* {t('heatPumpType')}</span></Label>
            </Col>
            <Col xs={12} className={s.select2}>
              <SelectWithTranslations
                key={'_sel_' + this.hvacKey}
                classNamePrefix="react-select"
                className="selectCustomization"
                options={listsData.list.heatPumpUpgradeTypes}
                value={this.state.heatPumpUpgradeTypeSelected}
                name='heatPumpUpgradeType'
                onChange={this.handleChange.bind(this)}
              />
              <br />
            </Col>
            {window.GET_FF('eaf-hspf-cop-inputs-flag') ? [
              <Col xs={12}>
                {/*<Label for="heatPumpCOP"><span className={this.state.errorHeatPumpCOP ? "flabel-error" : ""}>* {t('heatingEfficiencyHSPF')}</span></Label>*/}
              </Col>,
              <Col xs={12}>
                <HEInput extraClass={this.state.errorHeatPumpCOP ? "flabel-error" : ""} valueCOP={this.state.heatPumpCOP} valueHSPF={this.state.heatPumpHSPF} onChange={(valueCOP, valueHSPF) => {
                  this.setState({
                    heatPumpCOP: valueCOP,
                    heatPumpHSPF: valueHSPF,
                    errorHeatPumpCOP: !(valueCOP >= 1) || !valueHSPF
                  });
                  this.props.building.heatPumpCOP = valueCOP;
                  this.props.building.heatPumpHSPF = valueHSPF;
                }}/>
                <br />
              </Col>
            ] : [
              <Col xs={12}>
                <Label for="heatPumpCOP"><span className={this.state.errorHeatPumpCOP ? "flabel-error" : ""}>* {t('heatingEfficiencyHSPF')}</span></Label>
              </Col>,
              <Col xs={12}>
                <Input type="number" defaultValue={this.state.heatPumpCOP} name='heatPumpCOP' onChange={this.handleChange.bind(this)} />
              </Col>,
              <br/>,<br/>
            ]}
            <Col xs={12}>
              <Label for="heatPumpHPCAP"><span className={this.state.errorHeatPumpHPCAP ? "flabel-error" : ""}>* {t('heatingCapacityBTU')}</span></Label>
            </Col>
            <Col xs={12}>
              <Input type="number" defaultValue={this.state.heatPumpHPCAP} name='heatPumpHPCAP' onChange={this.handleChange.bind(this)} />
              <br />
            </Col>
            <Col xs={12}>
              <Label for="heatPumpElectricalServiceSize"><span className={this.state.errorHeatPumpESS ? "flabel-error" : ""}>{t('electricalServiceSize')}</span></Label>
            </Col>
            <Col xs={12}>
              <Input type="number" defaultValue={this.state.heatPumpElectricalServiceSize} name='heatPumpElectricalServiceSize' onChange={this.handleChange.bind(this)} />
              <br />
            </Col>
            {/*'central' === this.state.heatPumpUpgradeTypeSelected?.[0]?.value || ('mshp-ductless' === this.state.heatPumpUpgradeTypeSelected?.[0]?.value) ? <div>
              <Col xs={12}>
                <Label>Efficiency NS Moderate Income Rebate</Label>
              </Col>
              <Col xs={12}>
                <img src={this.state.moderateIncomeRebate ? '/images/checkbox-checked.png' : '/images/checkbox-unchecked.png'} className='accordion-checkbox' onClick={(e) => { this.setState({ moderateIncomeRebate: !this.state.moderateIncomeRebate }); }} />
                <br />
              </Col>
              <br/>
            </div> : undefined*/}
            {/*'central' === this.state.heatPumpUpgradeTypeSelected?.[0]?.value || 'mshp' === this.state.heatPumpUpgradeTypeSelected?.[0]?.value || 'mshp-ductless' === this.state.heatPumpUpgradeTypeSelected?.[0]?.value ? <div>
              <Col xs={12}>
                <Label>Efficiency NS OHPA Rebate</Label>
              </Col>
              <Col xs={12}>
                <img src={this.state.ohpaRebate ? '/images/checkbox-checked.png' : '/images/checkbox-unchecked.png'} className='accordion-checkbox' onClick={(e) => { this.setState({ ohpaRebate: !this.state.ohpaRebate }); }} />
                <br />
              </Col>
              <br/>
            </div> : undefined*/}
            <HSUpgrade onChange={(model) => {this.props.building.hs_upgrade_json = JSON.stringify(model); this.props.onStateChange(this.getBuildingInputs());}} />
            <HPDetails has_heads={'mshp' === this.state.heatPumpUpgradeTypeSelected?.[0]?.value || 'mshp-ductless' === this.state.heatPumpUpgradeTypeSelected?.[0]?.value} is_upgrade={true} is_primary={true} onChange={(model) => {this.props.building.hp_details_json = JSON.stringify(model); this.props.onStateChange(this.getBuildingInputs());}} />
          </FormGroup>
          {!window.HIDE_AUTO_REBATES && <div className={'rc-est-rebate-non-rc ' + (Math.round(this.state.heatPumpUpgradeEstRebate || 0) > 0 ? 'rc-est-rebate-green' : '')}>Est. Rebate: ${Math.round(this.state.heatPumpUpgradeEstRebate || 0)}</div>}
        </Col>
      );
    }
    else if (title == "Doors And Windows") {
      let bothEmptyError = !parseFloat(this.state.totalNumberOfInstalledEStarDoors) && !parseFloat(this.state.totalNumberOfInstalledEStarWindows);
      return (
        <Col xs={12} key={this.windoorKey}>
          <FormGroup row>
            <Col xs={12}>
              {!!this.state.preTotalNumberOfInstalledEStarDoors && <Label for="normal-field"><span className={(bothEmptyError || this.state.errorEStarDoors) ? "flabel-error" : ""}>* {t('numberOfEEDoors')} ({t('includingExisting')}: {this.state.preTotalNumberOfInstalledEStarDoors})</span></Label>}
              {!this.state.preTotalNumberOfInstalledEStarDoors && <Label for="normal-field"><span className={(bothEmptyError || this.state.errorEStarDoors) ? "flabel-error" : ""}>* {t('numberOfEEDoors')}</span></Label>}
            </Col>
            <Col xs={12} className={s.select2}>
              <Input type="number" defaultValue={this.state.totalNumberOfInstalledEStarDoors} name='totalNumberOfInstalledEStarDoors' onChange={this.handleChange.bind(this)} />
              <br />
            </Col>
            <Col xs={12}>
              {!!this.state.preTotalNumberOfInstalledEStarWindows && <Label for="normal-field"><span className={(bothEmptyError || this.state.errorEStarWindows) ? "flabel-error" : ""}>* {t('numberOfEEWindows')} ({t('includingExisting')}: {this.state.preTotalNumberOfInstalledEStarWindows})</span></Label>}
              {!this.state.preTotalNumberOfInstalledEStarWindows && <Label for="normal-field"><span className={(bothEmptyError || this.state.errorEStarWindows) ? "flabel-error" : ""}>* {t('numberOfEEWindows')}</span></Label>}
            </Col>
            <Col xs={12} className={s.select2}>
              <Input type="number" defaultValue={this.state.totalNumberOfInstalledEStarWindows} name='totalNumberOfInstalledEStarWindows' onChange={this.handleChange.bind(this)} />
              <br />
            </Col>
          </FormGroup>
          {!window.HIDE_AUTO_REBATES && <div className={'rc-est-rebate-non-rc ' + (Math.round(this.state.winDoorUpgradeEstRebate || 0) > 0 ? 'rc-est-rebate-green' : '')}>Est. Rebate: ${Math.round(this.state.winDoorUpgradeEstRebate || 0)}</div>}
        </Col>
      );
    }

  }

  componentDidUpdate(prevProps) {

    if (this.state.loaderActive && !this.props.isUpdating) {
      this.setState({
        loaderActive: false
      });
      return;
    }

    let newModelStr = JSON.stringify(this.props.model);

    if (newModelStr === this.lastModelStr) {
      return;
    }

    this.lastModelStr = newModelStr;

    let aContent = [
      {
        title: 'Heat Pump', body: ``,
      },
      {
        title: 'Domestic Hot Water', body: '', hidden: true
      },
      {
        title: 'Wall Insulation', body: ``,
      },
      {
        title: 'Ceiling Insulation', body: ``,
      }
    ];
    aContent.push(
      {
        title: 'Foundation Insulation', body: ``,
        hidden: !(this.props.building.basementFloorArea > 0)
      }
    );
    aContent.push(
      {
        title: 'Doors And Windows', body: ``,
      }
    );

    let a1Array = [];
    a1Array.length = aContent.length;
    a1Array.fill(false);
    let cbArray = [];
    cbArray.length = aContent.length;
    cbArray.fill(false);

    let ndac = this.props.building.upgradeScenariosChecked;
    if (!ndac) {
      ndac = null;
    }
    else {
      ndac = JSON.parse(ndac);
    }
    if (JSON.stringify(ndac) !== JSON.stringify(this.defaultAccordionCheck)) {
      this.defaultAccordionCheck = ndac;
    }

    if (this.defaultAccordionCheck) {
      cbArray = [...this.defaultAccordionCheck];
    }

    if (this.props.isButtonEnabledCbk) {
      this.props.isButtonEnabledCbk(this.getButtonEnabled.bind(this));
    }

    this.state.accordionFirst = a1Array;
    this.state.accordionFirstContent = aContent;
    this.state.accordionCheckbox = cbArray;

    let isInit = !this.state.init;

    this.setState({
      init: true,
      ld: '',
      initEchartsOptions: {
        renderer: 'canvas'
      },
      hoverData: null,
      dropdownOpen: false,
      accordionFirst: a1Array,
      accordionFirstContent: aContent,
      accordionCheckbox: cbArray,
      upgradeDisabled: this.countTrue(cbArray) === 0,
      errorHeatPumpCOP: !(this.props.building.heatingCOP >= 1),
      errorHeatPumpHPCAP: !(this.props.building.heatPumpHPCAP > 0),
      //errorHeatPumpESS: !(this.props.building.heatPumpElectricalServiceSize > 0),
      errorHeatPumpType: !(this.props.building.heatPumpUpgradeType),
      errorEStarWindows: !this.props.building.totalNumberOfInstalledEStarWindowsPostUpgrade && this.props.building.totalNumberOfInstalledEStarWindowsPostUpgrade !== 0,
      errorEStarDoors: !this.props.building.totalNumberOfInstalledEStarDoorsPostUpgrade && this.props.building.totalNumberOfInstalledEStarDoorsPostUpgrade !== 0
    });
    if (this.props.onStateChange) {
      this.props.onStateChange(this.getBuildingInputs(), isInit);
    }
  }

  getButtonEnabled() {
    let anyUpgradeError = this.state.accordionCheckbox && ((this.state.accordionCheckbox[0] && (this.state.errorHeatPumpHPCAP || this.state.errorHeatPumpESS || this.state.errorHeatPumpCOP || this.state.errorHeatPumpType)) ||
                          (this.state.accordionCheckbox[2] && this.state.errorWalls) ||
                          (this.state.accordionCheckbox[3] && this.state.errorCeiling) ||
                          (this.state.accordionCheckbox[4] && this.state.errorFoundation) ||
                          (this.state.accordionCheckbox[5] && (this.state.errorEStarWindows || this.state.errorEStarDoors || (!parseFloat(this.state.totalNumberOfInstalledEStarDoors) && !parseFloat(this.state.totalNumberOfInstalledEStarWindows)))));
    return !(this.state.upgradeDisabled || anyUpgradeError) || (this.lastDSCTimer != -1);
  }

  toggleAccordionFirst(id) {
    const arr = [];
    arr.length = this.state.accordionFirst.length;
    arr.fill(false);
    arr[id] = !this.state.accordionFirst[id];
    this.setState({
      accordionFirst: arr,
    });
    this.delayStateChange();
  }

  countTrue(arr) {
    let count = 0;
    for (let i=0; arr && i<arr.length; i++) {
      count += arr[i] ? 1 : 0;
    }
    return count;
  }

  accordionCheckToggle(e, id) {
    window._RA_MADE_CHANGES = true;
    const arr = this.state.accordionCheckbox;
    arr[id] = !arr[id];
    this.setState({
      accordionCheckbox: arr,
      upgradeDisabled: this.countTrue(arr) === 0
    });
    e = e || window.event;
    e.preventDefault();
    e.stopPropagation();
    this.delayStateChange();
    return false;
  }

  setHoverData = (e) => {
    this.setState({ hoverData: e.target.category });
  }

  render() {

    const {t} = this.props;

    const renderField = (field) => {
      return (
        <div className='ac-field' key={field.rkey}>
          <Col xs={12}>
            <Label for="normal-field">{field.title}</Label>
          </Col>
          <Col xs={12}>
            <Input type="text" defaultValue={field.value} name={field.key} readOnly></Input>
          </Col>
        </div>
      );
    };

    let anyUpgradeError = this.state.accordionCheckbox && ((this.state.accordionCheckbox[0] && (this.state.errorHeatPumpHPCAP || this.state.errorHeatPumpESS || this.state.errorHeatPumpCOP || this.state.errorHeatPumpType)) ||
                          (this.state.accordionCheckbox[2] && this.state.errorWalls) ||
                          (this.state.accordionCheckbox[3] && this.state.errorCeiling) ||
                          (this.state.accordionCheckbox[4] && this.state.errorFoundation) ||
                          (this.state.accordionCheckbox[5] && (this.state.errorEStarWindows || this.state.errorEStarDoors || (!parseFloat(this.state.totalNumberOfInstalledEStarDoors) && !parseFloat(this.state.totalNumberOfInstalledEStarWindows)))));

    if (!this.state.init) {
      return (<div />);
    }
    return (
      <div>
        <h1 className="pane-title" style={{marginBottom: "15px"}}>{t('upgrades')}<span
          className="fw-semi-bold"
        >{anyUpgradeError && <div className='ups-error-dot'></div>}</span></h1>
        <br />
        {/* Accordion */}
        <Row className="mt-xs">
          <Col md="12" xs="12" className='mb-lg'>
            {this.state.accordionFirstContent.map((element, index) => (
              !element.hidden && <div className="ac-cont panel mb-xs" key={`accord-one-${index.toString()}`}>
                { /* eslint-disable */}
                <div
                  className="ac-header panel-header" role="button"
                  onClick={() => {
                    this.toggleAccordionFirst(index)
                  }}>
                  { /* eslint-enable */}
                  <div className="mb-0">
                    {/* eslint-disable-next-line */}
                    <img src={this.state.accordionCheckbox[index] ? '/images/checkbox-checked.png' : '/images/checkbox-unchecked.png'} className='accordion-checkbox' onClick={(e) => { return this.accordionCheckToggle(e, index); }} />
                    {/* eslint-disable-next-line */}
                    <a className="accordion-toggle" role="button">
                      <div className={"ac-header-text " + (this.headerTitleError(element.title) ? "ac-header-error" : "")}><div className="rvc-error-dot"></div>{t(element.title)}</div>
                      <i className={`fa fa-angle-down ${this.state.accordionFirst[index] ? 'expanded' : ''}`} />
                    </a>
                  </div>
                </div>
                <Collapse className={'panel-body ' + (!this.state.accordionCheckbox[index] ? 'panel-disabled' : '')} isOpen={this.state.accordionFirst[index]}>
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: element.body }} />
                    <br />
                    <div className="ac-field-list">
                    </div>
                    <div>{element.component}</div>
                    {this.renderAccordionInner(element.title)}
                  </div>
                </Collapse>
              </div>
            ))}
          </Col>
        </Row>
        <Row>
          <Col xs={12} >
            <FormGroup className="form-action bg-widget-transparent">
              <Button onClick={this.handleUpgradeSubmit} className="mr-xs pull-right mid-button" color="primary" type="submit" disabled={this.state.upgradeDisabled || anyUpgradeError}>
                {t('modelUpgrades')}
              </Button>
              {anyUpgradeError && <div className="upgrade-model-error-msg">{t('errorUpgradeScenariosPerformUpgrade')}</div>}
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let model = state.buildings.curModel || {};
  let building = state.buildings.curBuilding.building || {};

  return { model, building, isUpdating: state.buildings.isUpdating };
}
export default withTranslation()(withRouter(connect(mapStateToProps)(UpgradeScenariosPanel)));
