import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Input,
 //  UncontrolledAlert,
  Dropdown,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  ButtonGroup,
  Button,
  Form,
  FormGroup,
} from 'reactstrap';
import { logoutUser } from '../../actions/auth';
import { openSidebar, closeSidebar, changeSidebarPosition, changeSidebarVisibility } from '../../actions/navigation';
import s from './Header.module.scss';
import { changeLanguage } from 'i18next';

class Header extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebarPosition: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.doLogout = this.doLogout.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.toggleMessagesDropdown = this.toggleMessagesDropdown.bind(this);
    this.toggleSupportDropdown = this.toggleSupportDropdown.bind(this);
    this.toggleSettingsDropdown = this.toggleSettingsDropdown.bind(this);
    this.toggleAccountDropdown = this.toggleAccountDropdown.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.toggleSearchOpen = this.toggleSearchOpen.bind(this);

    this.state = {
      visible: true,
      messagesOpen: false,
      supportOpen: false,
      settingsOpen: false,
      searchFocused: false,
      searchOpen: false,
      notificationsOpen: false,
      langToggleOpen: false,
    };
  }

  toggleNotifications = () => {
  }

  onDismiss() {
    this.setState({ visible: false });
  }

  doLogout() {
    this.props.dispatch(logoutUser());
  }

  toggleMessagesDropdown() {
    this.setState({
      messagesOpen: !this.state.messagesOpen,
    });
  }

  toggleSupportDropdown() {
    this.setState({
      supportOpen: !this.state.supportOpen,
    });
  }

  toggleSettingsDropdown() {
    this.setState({
      settingsOpen: !this.state.settingsOpen,
    });
  }

  toggleAccountDropdown() {
    this.setState({
      accountOpen: !this.state.accountOpen,
    });
  }

  toggleSearchOpen() {
    this.setState({
      searchOpen: !this.state.searchOpen,
    });
  }

  toggleSidebar() {
    this.props.isSidebarOpened 
      ? this.props.dispatch(closeSidebar())
      : this.props.dispatch(openSidebar())
  }

  moveSidebar(position) {
    this.props.dispatch(changeSidebarPosition(position));
  }

  toggleVisibilitySidebar(visibility) {
    this.props.dispatch(changeSidebarVisibility(visibility));
  }

  changeLanguage(lang) {
    changeLanguage(lang);
    this.setState({ langToggleOpen: false });
  }

  langToggle(t) {
		return (
			<Dropdown
				className="mx-3"
				nav
				isOpen={this.state.langToggleOpen}
				toggle={() =>
					this.setState({ langToggleOpen: !this.state.langToggleOpen })
				}
			>
				<DropdownToggle
					nav
					className="bg-primary rounded-circle flex justify-center items-center"
					style={{ width: '40px', height: '40px' }}
				>
					<i className="fa fa-globe" />
				</DropdownToggle>
				<DropdownMenu>
					<DropdownItem
						onClick={() => this.changeLanguage('en')}
						className="px-3"
					>
						English
						{localStorage?.i18nextLng === 'en' && (
							<i
								role="img"
								aria-label={t ? t('selected') : 'Selected'}
								className="fa fa-check ml-2"
							/>
						)}
					</DropdownItem>
					<DropdownItem onClick={() => this.changeLanguage('fr')}>
						French
						{localStorage?.i18nextLng === 'fr' && (
							<i
								role="img"
								aria-label={t ? t('selected') : 'Selected'}
								className="fa fa-check ml-2"
							/>
						)}
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);
	}

  render() {
    const {t} = this.props;
   // eslint-disable-next-line
    const user = this.props.currentUser;
   // eslint-disable-next-line
    const avatar = user && user.avatar && user.avatar.length && user.avatar[0].publicUrl;

    const firstUserLetter = user && (user.firstName|| user.email)[0].toUpperCase();
    return (
      <Navbar className={`d-print-none ${s.root}`}>
       
        <Collapse className={`${s.searchCollapse} ml-lg-0 mr-md-3`} isOpen={this.state.searchOpen}>
          <InputGroup className={`${s.navbarForm} ${this.state.searchFocused ? s.navbarFormFocused : ''}`}>
            <InputGroupAddon addonType="prepend" className={s.inputAddon}><InputGroupText><i className="fa fa-search" /></InputGroupText></InputGroupAddon>
            <Input
              id="search-input-2" placeholder="Search..." className="input-transparent"
              onFocus={() => this.setState({ searchFocused: true })}
              onBlur={() => this.setState({ searchFocused: false })}
            />
          </InputGroup>
        </Collapse>
        <Nav className="ml-md-0">
        {this.langToggle(t)}
          <Dropdown nav isOpen={false && this.state.notificationsOpen} onClick={(e) => {
           e.preventDefault();
              window.location.href='#/app/main/account';   
        }} toggle={() => {}} id="basic-nav-dropdown" className={`${s.notificationsMenu}`}>
            <DropdownToggle nav style={{color: "#f4f4f5", padding: 0}}>
            
            <span className={`${s.avatar} rounded-circle float-left mr-2`}>
              <span title={user && (user.firstName || user.email)}>{firstUserLetter}</span>
            </span>
              <span className={`small d-sm-down-none ${this.props.sidebarStatic ? s.adminEmail : ''}`}>{user ? (user.firstName || user.email) : "-"}</span>
  
              {false && <Badge className={s.badge} color="primary">13</Badge>}
            </DropdownToggle>
            <DropdownMenu right className={`${s.notificationsWrapper} py-0 animated animated-fast fadeInUp`}>
            </DropdownMenu>
          </Dropdown>
          <NavItem className={`${s.divider} text-white`} />
          <NavItem>
            <NavLink onClick={this.doLogout} className={`${s.navItem} text-white`} href="#">
              <i className="glyphicon glyphicon-off" />
            </NavLink>
          </NavItem>
          <NavItem className="d-md-none">
            <NavLink onClick={this.toggleSidebar} className={`${s.navItem} text-white`} href="#">
              <i className="fa fa-bars" />
            </NavLink>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

function mapStateToProps(store) {
  return {
    isSidebarOpened: store.navigation.sidebarOpened,
    sidebarVisibility: store.navigation.sidebarVisibility,
    sidebarPosition: store.navigation.sidebarPosition,
    currentUser: store.auth.currentUser,
  };
}

export default withRouter(connect(mapStateToProps)(Header));

