import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
const SelectWithTranslations = ({ options, value, ...props }) => {
	const { i18n } = useTranslation();
	const translatedOptions = options.map((option) => ({
		...option,
		label: i18n.t(`${option.label}`),
	}));
	const getTranslatedValue = (value) => {
		if (!value) {
			return value;
		}
		if (value instanceof Array) {
			return value.map(v => getTranslatedValue(v));
		}
		return {
			label: i18n.t(`${value.label}`),
			value: value.value,
		};
	};
	return (
		<Select
			options={translatedOptions}
			value={getTranslatedValue(value)}
			{...props}
		/>
	);
};

export default SelectWithTranslations;
