/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, FormGroup, Label, Input, Collapse } from 'reactstrap';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { updateAuditorNotesRequest, getBuildingRequest, getBuildingRequestLM, updateBuildingRequest, updateReportRequest } from '../../../../actions/buildings';
import './ReportInfoPanel.scss';
import s from './ReportInfoPanel.module.scss';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';

class ReportInfoPanel extends React.Component {

  static propTypes = {
    model: PropTypes.object,
    building: PropTypes.object,
    isUpdating: PropTypes.bool
  };

  static defaultProps = {
    model: {},
    building: {}
  };

  constructor(props) {
    super(props);

    this.handleUpdateDraft = this.handleUpdateDraft.bind(this);

    this.state = {
    };

    this.toggleAccordionFirst = this.toggleAccordionFirst.bind(this);

    this.defaultAccordionCheck = JSON.parse(props.building.upgradeScenariosChecked || '[]');
    if (!this.defaultAccordionCheck.length) {
      this.defaultAccordionCheck = null;
    }

    this.inotesRef = React.createRef();

    this.lastModelStr = JSON.stringify({});

    this.recoChange = true;

    if (this.props.getHandleUpdateDraft) {
      this.props.getHandleUpdateDraft(this.handleUpdateDraft);
    }
  }

  componentDidMount() {
    const buildingId = this.props.match.params.buildingId;

    this.props.dispatch(getBuildingRequest(buildingId));
    this.props.dispatch(getBuildingRequestLM(buildingId));
  }
 

  handleUpdateDraft(event, isQuickSave, isPublish) {
    let R = {};
    if (!this.props.building.basementFloorArea) {
      R['upgrade_foundation'] = {
        recommend: false,
        comment: ''
      };
    }
    for (let i=0; i<this.state.accordionFirstContent.length-1; i++) {
      const S = this.state.accordionFirstContent[i];
      const C = this.state.accordionCheckbox[i];
      if (!R[S.obj_name]) {
        R[S.obj_name] = {
          recommend: false,
          comment: ''
        };
      }
      R[S.obj_name].recommend = !!C;
      R[S.obj_name].comment = `${this.state[`${S.namePrefix}Comment`]||''}`.trim();
    }
    R.overall_comments = `${this.state.generalComment||''}`.trim();
    this.buildingInputs = {
      buildingId: this.props.match.params.buildingId,
      report_override: {
        recommendations: R
      }
    };
    if (this.state.commentsSaveWaiting) {
      window.clearTimeout(this.commentsSaveTimeoutID);
      this.setState({ commentsSaveWaiting: false });
      this.commentsSaveTimeoutID = null;
    }
    this.props.dispatch(updateReportRequest(this.buildingInputs, isQuickSave, isPublish));
    this.setState({
      loaderActive: isPublish ? true : false,
      canUpdateDraft: false
    });
    if (event) {
      event.preventDefault();
    }
  }

  handleChange(e, parent) {
    if (!parent) {
      parent = e.target;
    }
    let obj = {}; obj[parent.name] = parent.value;
    if (parent.name.indexOf('Comment') > 0) {
      let max = parent.name === 'generalComment' ? 2000 : 1000;
      if (obj[parent.name].length > max) {
        obj[parent.name] = obj[parent.name].substr(0, max);
      }
    }
    this.setState(obj);

    this.commentsChanged(e);
  }

  notesChanged(e) {

    if (this.state.noteSaveWaiting) {
      window.clearTimeout(this.noteSaveTimeoutID);
    }

    this.noteSaveTimeoutID = window.setTimeout(() => {
      this.props.dispatch(updateAuditorNotesRequest({
        buildingId: this.props.building.buildingId,
        internalNotes: this.inotesRef.current.value
      }));
      this.setState({ noteSaveWaiting: false });
      this.noteSaveTimeoutID = null;
    }, 3000);
    this.setState({ noteSaveWaiting: true });

  }

  commentsChanged(e) {

    if (this.state.commentsSaveWaiting) {
      window.clearTimeout(this.commentsSaveTimeoutID);
    }

    this.commentsSaveTimeoutID = window.setTimeout(() => {
      this.handleUpdateDraft(e, true);
      this.setState({canUpdateDraft: true});
      this.setState({ commentsSaveWaiting: false });
      this.commentsSaveTimeoutID = null;
    }, 3000);
    this.setState({ commentsSaveWaiting: true });

  }

  renderAccordionInner(title) {

    const {t} = this.props;

    if (title == "Wall Insulation") {
      return (
        <Col xs={12}>
          <FormGroup row>
            <Col xs={3}>
              <Label for="wallImpact">{t('adjustedSavingGjYear')}</Label>
            </Col>
            <Col xs={3}>
              <Input type="number" value={this.state.wallImpact} placeholder="10.0" name='wallImpact' onChange={this.handleChange.bind(this)} readOnly={true} />
              <br />
            </Col>
            {!window.HIDE_DOLLARS && <Col xs={3}>
              <Label for="wallImpactDollar">{t('savingsInDollarsCAD')}</Label>
            </Col>}
            {!window.HIDE_DOLLARS && <Col xs={3}>
              <Input type="number" value={this.state.wallImpactDollar} placeholder="" name='wallImpactDollar' onChange={this.handleChange.bind(this)} readOnly={true} />
              <br />
            </Col>}
            {window.HIDE_DOLLARS && <Col xs={6}></Col>}
            <Col xs={3}>
              <Label for="wallComment">{t('commentsOnUpgrade')} ({this.state.wallComment.length}/1000) <span style={this.state.commentsSaveWaiting === false ? {color: '#10D010'} : this.state.commentsSaveWaiting === true ? {color: '#F04035'} : {}}>{this.state.commentsSaveWaiting === false ? ' ('+t('saved')+')' : this.state.commentsSaveWaiting === true ? ' ('+t('unsaved')+')' : ''}</span></Label>
            </Col>
            <Col xs={9}>
              <Input type="textarea" value={this.state.wallComment} name='wallComment' onChange={this.handleChange.bind(this)} rows={6}/>
              <br />
            </Col>
          </FormGroup>
        </Col>
      );
    }
    if (title == "Ceiling Insulation") {
      return (
        <Col xs={12}>
          <FormGroup row>
            <Col xs={3}>
              <Label for="ceilingImpact">{t('adjustedSavingGjYear')}</Label>
            </Col>
            <Col xs={3}>
              <Input type="number" value={this.state.ceilingImpact} placeholder="10.0" name='ceilingImpact' onChange={this.handleChange.bind(this)} readOnly={true} />
              <br />
            </Col>
            {!window.HIDE_DOLLARS && <Col xs={3}>
              <Label for="ceilingImpactDollar">{t('savingsInDollarsCAD')}</Label>
            </Col>}
            {!window.HIDE_DOLLARS && <Col xs={3}>
              <Input type="number" value={this.state.ceilingImpactDollar} placeholder="" name='ceilingImpactDollar' onChange={this.handleChange.bind(this)} readOnly={true} />
              <br />
            </Col>}
            {window.HIDE_DOLLARS && <Col xs={6}></Col>}
            <Col xs={3}>
              <Label for="ceilingComment">{t('commentsOnUpgrade')} ({this.state.ceilingComment.length}/1000) <span style={this.state.commentsSaveWaiting === false ? {color: '#10D010'} : this.state.commentsSaveWaiting === true ? {color: '#F04035'} : {}}>{this.state.commentsSaveWaiting === false ? ' ('+t('saved')+')' : this.state.commentsSaveWaiting === true ? ' ('+t('unsaved')+')' : ''}</span></Label>
            </Col>
            <Col xs={9}>
              <Input type="textarea" value={this.state.ceilingComment} name='ceilingComment' onChange={this.handleChange.bind(this)} rows={6} />
              <br />
            </Col>
          </FormGroup>
        </Col>
      );
    }
    if (title == "Foundation Insulation") {
      return (
        <Col xs={12}>
          <FormGroup row>
            <Col xs={3}>
              <Label for="foundImpact">{t('adjustedSavingGjYear')}</Label>
            </Col>
            <Col xs={3}>
              <Input type="number" value={this.state.foundImpact} placeholder="10.0" name='foundImpact' onChange={this.handleChange.bind(this)} readOnly={true} />
              <br />
            </Col>
            {!window.HIDE_DOLLARS && <Col xs={3}>
              <Label for="foundImpactDollar">{t('savingsInDollarsCAD')}</Label>
            </Col>}
            {!window.HIDE_DOLLARS && <Col xs={3}>
              <Input type="number" value={this.state.foundImpactDollar} placeholder="" name='foundImpactDollar' onChange={this.handleChange.bind(this)} readOnly={true} />
              <br />
            </Col>}
            {window.HIDE_DOLLARS && <Col xs={6}></Col>}
            <Col xs={3}>
              <Label for="foundComment">{t('commentsOnUpgrade')} ({this.state.foundComment.length}/1000) <span style={this.state.commentsSaveWaiting === false ? {color: '#10D010'} : this.state.commentsSaveWaiting === true ? {color: '#F04035'} : {}}>{this.state.commentsSaveWaiting === false ? '('+t('saved')+')' : this.state.commentsSaveWaiting === true ? ' ('+t('unsaved')+')' : ''}</span></Label>
            </Col>
            <Col xs={9}>
              <Input type="textarea" value={this.state.foundComment} name='foundComment' onChange={this.handleChange.bind(this)} rows={6} />
              <br />
            </Col>
          </FormGroup>
        </Col>
      );
    }
    if (title == "Domestic Hot Water") {
      return (
        <Col xs={12}>
          <FormGroup row>
            <Col xs={3}>
              <Label for="dhwImpact">{t('adjustedSavingGjYear')}</Label>
            </Col>
            <Col xs={3}>
              <Input type="number" value={this.state.dhwImpact} placeholder="10.0" name='dhwImpact' onChange={this.handleChange.bind(this)} readOnly={true} />
              <br />
            </Col>
            {!window.HIDE_DOLLARS && <Col xs={3}>
              <Label for="dhwImpactDollar">{t('savingsInDollarsCAD')}</Label>
            </Col>}
            {!window.HIDE_DOLLARS && <Col xs={3}>
              <Input type="number" value={this.state.dhwImpactDollar} placeholder="" name='dhwImpactDollar' onChange={this.handleChange.bind(this)} readOnly={true} />
              <br />
            </Col>}
            {window.HIDE_DOLLARS && <Col xs={6}></Col>}
            <Col xs={3}>
              <Label for="dhwComment">{t('commentsOnUpgrade')} ({this.state.dhwComment.length}/1000) <span style={this.state.commentsSaveWaiting === false ? {color: '#10D010'} : this.state.commentsSaveWaiting === true ? {color: '#F04035'} : {}}>{this.state.commentsSaveWaiting === false ? '('+t('saved')+')' : this.state.commentsSaveWaiting === true ? ' ('+t('unsaved')+')' : ''}</span></Label>
            </Col>
            <Col xs={9}>
              <Input type="textarea" value={this.state.dhwComment} name='dhwComment' onChange={this.handleChange.bind(this)} rows={6} />
              <br />
            </Col>
          </FormGroup>
        </Col>
      );
    }
    if (title == "HVAC")
      return (
        <Col xs={12}>
          <FormGroup row>
            <Col xs={3}>
              <Label for="hvacImpact">{t('adjustedSavingGjYear')}</Label>
            </Col>
            <Col xs={3}>
              <Input type="number" value={this.state.hvacImpact} placeholder="10.0" name='hvacImpact' onChange={this.handleChange.bind(this)} readOnly={true} />
              <br />
            </Col>
            {!window.HIDE_DOLLARS && <Col xs={3}>
              <Label for="hvacImpactDollar">{t('savingsInDollarsCAD')}</Label>
            </Col>}
            {!window.HIDE_DOLLARS && <Col xs={3}>
              <Input type="number" value={this.state.hvacImpactDollar} placeholder="" name='hvacImpactDollar' onChange={this.handleChange.bind(this)} readOnly={true} />
              <br />
            </Col>}
            {window.HIDE_DOLLARS && <Col xs={6}></Col>}
            <Col xs={3}>
              <Label for="hvacComment">{t('commentsOnUpgrade')} ({this.state.hvacComment.length}/1000) <span style={this.state.commentsSaveWaiting === false ? {color: '#10D010'} : this.state.commentsSaveWaiting === true ? {color: '#F04035'} : {}}>{this.state.commentsSaveWaiting === false ? '('+t('saved')+')' : this.state.commentsSaveWaiting === true ? ' ('+t('unsaved')+')' : ''}</span></Label>
            </Col>
            <Col xs={9}>
              <Input type="textarea" value={this.state.hvacComment} name='hvacComment' onChange={this.handleChange.bind(this)} rows={6} />
              <br />
            </Col>
          </FormGroup>
        </Col>
      );
    if (title == "Doors And Windows") {
      return (
        <Col xs={12}>
          <FormGroup row>
            <Col xs={3}>
              <Label for="windoorImpact">{t('adjustedSavingGjYear')}</Label>
            </Col>
            <Col xs={3}>
              <Input type="number" value={this.state.windoorImpact} placeholder="10.0" name='windoorImpact' onChange={this.handleChange.bind(this)} readOnly={true} />
              <br />
            </Col>
            {!window.HIDE_DOLLARS && <Col xs={3}>
              <Label for="windoorImpactDollar">{t('savingsInDollarsCAD')}</Label>
            </Col>}
            {!window.HIDE_DOLLARS && <Col xs={3}>
              <Input type="number" value={this.state.windoorImpactDollar} placeholder="" name='windoorImpactDollar' onChange={this.handleChange.bind(this)} readOnly={true} />
              <br />
            </Col>}
            {window.HIDE_DOLLARS && <Col xs={6}></Col>}
            <Col xs={3}>
              <Label for="windoorComment">{t('commentsOnUpgrade')} ({this.state.windoorComment.length}/1000) <span style={this.state.commentsSaveWaiting === false ? {color: '#10D010'} : this.state.commentsSaveWaiting === true ? {color: '#F04035'} : {}}>{this.state.commentsSaveWaiting === false ? '('+t('saved')+')' : this.state.commentsSaveWaiting === true ? ' ('+t('unsaved')+')' : ''}</span></Label>
            </Col>
            <Col xs={9}>
              <Input type="textarea" value={this.state.windoorComment} name='windoorComment' onChange={this.handleChange.bind(this)} rows={6} />
              <br />
            </Col>
          </FormGroup>
        </Col>
      );
    }
    if (title == "Draft Proofing") {
      return (
        <Col xs={12}>
          <FormGroup row>
            <Col xs={3}>
              <Label for="draftImpact">{t('adjustedSavingGjYear')}</Label>
            </Col>
            <Col xs={3}>
              <Input type="number" value={this.state.draftImpact} placeholder="10.0" name='draftImpact' onChange={this.handleChange.bind(this)} readOnly={true} />
              <br />
            </Col>
            {!window.HIDE_DOLLARS && <Col xs={3}>
              <Label for="draftImpactDollar">{t('savingsInDollarsCAD')}</Label>
            </Col>}
            {!window.HIDE_DOLLARS && <Col xs={3}>
              <Input type="number" value={this.state.draftImpactDollar} placeholder="" name='draftImpactDollar' onChange={this.handleChange.bind(this)} readOnly={true} />
              <br />
            </Col>}
            {window.HIDE_DOLLARS && <Col xs={6}></Col>}
            <Col xs={3}>
              <Label for="draftComment">{t('commentsOnUpgrade')} ({this.state.draftComment.length}/1000) <span style={this.state.commentsSaveWaiting === false ? {color: '#10D010'} : this.state.commentsSaveWaiting === true ? {color: '#F04035'} : {}}>{this.state.commentsSaveWaiting === false ? '('+t('saved')+')' : this.state.commentsSaveWaiting === true ? ' ('+t('unsaved')+')' : ''}</span></Label>
            </Col>
            <Col xs={9}>
              <Input type="textarea" value={this.state.draftComment} name='draftComment' onChange={this.handleChange.bind(this)} rows={6} />
              <br />
            </Col>
          </FormGroup>
        </Col>
      );
    }
    if (title == "General Comments") {
      return (
        <Col xs={12}>
          <FormGroup row>
            <Col xs={12}>
              <Label for="generalComment">{t('commentsOnAllUpgrade')} ({this.state.generalComment.length}/2000) <span style={this.state.commentsSaveWaiting === false ? {color: '#10D010'} : this.state.commentsSaveWaiting === true ? {color: '#F04035'} : {}}>{this.state.commentsSaveWaiting === false ? '('+t('saved')+')' : this.state.commentsSaveWaiting === true ? ' ('+t('unsaved')+')' : ''}</span> ({t('onlyVisibleToHomeownerIfMultiple')})</Label>
            </Col>
            <Col xs={12}>
              <Input type="textarea" value={this.state.generalComment} name='generalComment' onChange={this.handleChange.bind(this)} rows={9} />
              <br />
            </Col>
          </FormGroup>
        </Col>
      );
    }
    if (title == "Auditor's Notes (Internal)") {
      return (
        <div>
          <Col xs={12}>
            <Label for="internal-notes">{t('internalNotesNotVisibleOnReport')}<span style={this.state.noteSaveWaiting === false ? {color: '#10D010'} : this.state.noteSaveWaiting === true ? {color: '#F04035'} : {}}>{this.state.noteSaveWaiting === false ? '('+t('saved')+')' : this.state.noteSaveWaiting === true ? ' ('+t('unsaved')+')' : ''}</span>:</Label>
          </Col>
          <Col xs={12}>
            <Input type="textarea" defaultValue={this.state.internalNotes} name={'internal-notes-input'} rows={15} onChange={this.notesChanged.bind(this)} innerRef={this.inotesRef}></Input>
          </Col>
        </div>
      );
    }

  }

  updateRecoErrorMessages() {

    const {t} = this.props;
    if (!this.state.accordionCheckbox || !this.state.accordionFirstContent) {
      return;
    }

    if (!this.recoChange) {
      return;
    }

    this.recoChange = false;

    let rate = this.props.pmodel.costs.ratePerGJ;
    let recos = [];
    for (let i=0; i<this.state.accordionFirstContent.length; i++) {
      if (this.state.accordionFirstContent[i].isReco) {
        let R = this.state.accordionFirstContent[i];
        R._savings_gj = parseFloat(this.state[`${R.namePrefix}ImpactDollar`]) || 0;
        R.error_type = 0;
        if (R._savings_gj <= 0) {
          R.error_type = 1;
          R.not_in_report_reason = t('recommendationNotRecommendedAddedToReport');
        }
        
        if (this.state.accordionCheckbox[i]) {
          recos.push(R);
        }
      }
    }

    recos.sort((a, b) => (b._savings_gj - a._savings_gj));

    /*for (let i=4; i<recos.length; i++) {
      recos[i].not_in_report_reason = 'This recommendation is not currently in the report. Only 4 recommendations can be included in the report. The 4 with the largest savings have been included. If you would like to include this recommendation, please deselect other recommendation(s).';
      recos[i].error_type = 2;
    }*/

    this.setState({accordionFirstContent: this.state.accordionFirstContent});

    this.setState({canUpdateDraft: true});

  }

  componentDidUpdate(prevProps) {
    if (prevProps.building.buildingId != this.props.building.buildingId || prevProps.pmodel.id != this.props.pmodel.id) {
      this.setState({ init: false });
    }

    if (this.state.loaderActive && !this.props.isUpdating) {
      this.setState({
        loaderActive: false
      });
      return;
    }

    if (!this.props.building.upgradeScenariosChecked) {
      return;
    }

    let ndac = JSON.parse(this.props.building.upgradeScenariosChecked);
    let aContent = [];
    if (ndac[0]) {
      aContent.push({ title: 'HVAC', body: ``, namePrefix: 'hvac', obj_name: 'upgrade_heating', isReco: true });
    }
    if (ndac[1]) {
      aContent.push({ title: 'Domestic Hot Water', body: ``, namePrefix: 'dhw', obj_name: 'upgrade_water_heating', isReco: true });
    }
    if (ndac[2]) {
      aContent.push({ title: 'Wall Insulation', body: ``, namePrefix: 'wall', obj_name: 'upgrade_walls', isReco: true });
    }
    if (ndac[3]) {
      aContent.push({ title: 'Ceiling Insulation', body: ``, namePrefix: 'ceiling', obj_name: 'upgrade_ceiling', isReco: true });
    }
    if (ndac[4] && this.props.building.basementFloorArea > 0) {
      aContent.push({ title: 'Foundation Insulation', body: ``, namePrefix: 'found', obj_name: 'upgrade_foundation', isReco: true });
    }
    if (ndac[5]) {
      aContent.push({ title: 'Doors And Windows', body: ``, namePrefix: 'windoor', obj_name: 'upgrade_windows_doors', isReco: true });
    }
    //aContent.push({ title: 'Draft Proofing', body: ``, namePrefix: 'draft', obj_name: 'upgrade_air_sealing', isReco: true });
    aContent.push({ title: 'General Comments', body: ``, namePrefix: 'general' });
    aContent.push({ title: 'Auditor\'s Notes (Internal)', body: `` });

    let a1Array = [];
    a1Array.length = aContent.length;
    a1Array.fill(false);
    let cbArray = [];
    cbArray.length = aContent.length;
    cbArray.fill(true);
    cbArray[cbArray.length-3] = false;
    a1Array[0] = true;

    let pmodel = JSON.parse(JSON.stringify(this.props.pmodel));

    let generalComment = '';

    if (pmodel.report_override) {
      let REC = pmodel.report_override.recommendations;
      for (let key in REC) {
        if (typeof REC[key] == 'object') {
          REC[key].est_savings_gj_year = undefined;
          delete REC[key].est_savings_gj_year;
        }
      }
      pmodel.recommendations = {...pmodel.recommendations};
      for (let key in REC) {
        if (typeof REC[key] == 'object') {
          pmodel.recommendations[key] = {...(pmodel.recommendations[key] || {recommend: false}), ...(REC[key])};
        }
        else {
          pmodel.recommendations[key] = REC[key];
        }
      }
      for (let i=0; i<(aContent.length-1); i++) {
        cbArray[i] = !!(pmodel.recommendations[aContent[i].obj_name]?.recommend);
      }
      generalComment = `${pmodel.report_override.recommendations.overall_comments||''}`.trim();
    }

    if (!this.state.init && this.props.pmodel && this.props.pmodel.recommendations) {

      let rate = this.props.pmodel.costs.ratePerGJ;
      this.setState({
        init: true,
        ld: '',
        hoverData: null,
        dropdownOpen: false,
        accordionFirst: a1Array,
        accordionFirstContent: aContent,
        accordionCheckbox: cbArray,
        hvacImpact: Math.round(10*pmodel.recommendations?.upgrade_heating?.est_savings_gj_year)/10,
        hvacImpactDollar: Math.round(pmodel?.__report_info?.recommendations?.filter((e) => !!e.is_heatpump)[0]?.est_savings_dollars),
        hvacComment: pmodel.recommendations?.upgrade_heating?.comment || '',
        dhwImpact: 2.0,
        dhwImpactDollar: Math.round(2.0*rate),
        dhwComment: pmodel.recommendations?.upgrade_dhw?.comment || '',
        wallImpact: Math.round(10*pmodel.recommendations?.upgrade_walls?.est_savings_gj_year)/10,
        wallImpactDollar: Math.round(pmodel?.__report_info?.recommendations?.filter((e) => !!e.is_wall)[0]?.est_savings_dollars),
        wallComment: pmodel.recommendations?.upgrade_walls?.comment || '',
        ceilingImpact: Math.round(10*pmodel.recommendations?.upgrade_ceiling?.est_savings_gj_year)/10,
        ceilingImpactDollar: Math.round(pmodel?.__report_info?.recommendations?.filter((e) => !!e.is_ceiling)[0]?.est_savings_dollars),
        ceilingComment: pmodel.recommendations?.upgrade_ceiling?.comment || '',
        foundImpact: Math.round(10*pmodel.recommendations?.upgrade_foundation?.est_savings_gj_year)/10,
        foundImpactDollar: Math.round(pmodel?.__report_info?.recommendations?.filter((e) => !!e.is_foundation)[0]?.est_savings_dollars),
        foundComment: pmodel.recommendations?.upgrade_foundation?.comment || '',
        windoorImpact: Math.round(10*pmodel.recommendations?.upgrade_windows_doors?.est_savings_gj_year)/10,
        windoorImpactDollar: Math.round(pmodel?.__report_info?.recommendations?.filter((e) => !!e.is_windoor)[0]?.est_savings_dollars),
        windoorComment: pmodel.recommendations?.upgrade_windows_doors?.comment || '',
        draftImpact: Math.round(10*200/rate)/10,
        draftImpactDollar: 200,
        draftComment: pmodel.recommendations?.upgrade_air_sealing?.comment || '',
        generalComment: generalComment,
        internalNotes: this.props.building.internalNotes
      });

      this.recoChange = true;
    }

    this.updateRecoErrorMessages();
  }

  toggleAccordionFirst(id) {
    const arr = [];
    arr.length = this.state.accordionFirst.length;
    arr.fill(false);
    arr[id] = !this.state.accordionFirst[id];
    this.setState({
      accordionFirst: arr,
    });
  }

  accordionCheckToggle(e, id) {
    const arr = this.state.accordionCheckbox;
    arr[id] = !arr[id];
    this.setState({
      accordionCheckbox: arr
    });
    e = e || window.event;
    e.preventDefault();
    e.stopPropagation();
    this.commentsChanged(e);
    this.recoChange = true;
    this.updateRecoErrorMessages();
    return false;
  }

  setHoverData = (e) => {
    this.setState({ hoverData: e.target.category });
  }

  render() {

    const {t} = this.props;

    const renderField = (field) => {
      return (
        <div className='ac-field' key={field.rkey}>
          <Col xs={12}>
            <Label for="normal-field">{field.title}</Label>
          </Col>
          <Col xs={12}>
            <Input type="text" defaultValue={field.value} name={field.key} readOnly></Input>
          </Col>
        </div>
      );
    };

    if (!this.state.init) {
      return (<div />);
    }
    return (
      <div>
        {/* Accordion */}
        <Row className="mt-xs">
          <Col md="12" xs="12" className='mb-lg'>
            {this.state.accordionFirstContent.map((element, index) => (
              <div className="ac-cont panel mb-xs" key={`accord-one-${index.toString()}`}>
                { /* eslint-disable */}
                <div
                  className="ac-header panel-header" role="button"
                  onClick={() => {
                    this.toggleAccordionFirst(index)
                  }}>
                  { /* eslint-enable */}
                  <div className="mb-0">
                    {/* eslint-disable-next-line */}
                    {((index+1) !== this.state.accordionFirstContent.length) && <img src={this.state.accordionCheckbox[index] ? '/images/checkbox-checked.png' : '/images/checkbox-unchecked.png'} className='accordion-checkbox' onClick={(e) => { return this.accordionCheckToggle(e, index); }} style={element.error_type === 2 ? {} : element.error_type === 1 ? {opacity: 0.5, pointerEvents: 'none', cursor: 'auto'}: {}} />}
                    {/* eslint-disable-next-line */}
                    <a className="accordion-toggle" role="button">
                      <div className="ac-header-text" style={[{}, {color: "#d08010"}, {color: "#d01010"}][element.error_type]}>{t(element.title)}</div>
                      <i className={`fa fa-angle-down ${this.state.accordionFirst[index] ? 'expanded' : ''}`} />
                    </a>
                  </div>
                </div>
                <Collapse className={'panel-body'} isOpen={this.state.accordionFirst[index]}>
                  {element.not_in_report_reason && (<div className={"ac-rec-error-" + element.error_type}>{element.not_in_report_reason}</div>)}
                  {element.error_type === 1 && (<div style={{color: "#d08010"}}>
                    <br/>
                    <img src={this.state.accordionCheckbox[index] ? '/images/checkbox-checked.png' : '/images/checkbox-unchecked.png'} className='accordion-checkbox-inline' onClick={(e) => { return this.accordionCheckToggle(e, index); }} />
                    {t('addRecommendationToReportEvenNotRecommended')}
                  </div>)}
                  <div style={element.error_type === 2 || !this.state.accordionCheckbox[index] ? {opacity: 0.5, pointerEvents: "none"} : {}}>
                    <div dangerouslySetInnerHTML={{ __html: element.body }} />
                    <br />
                    <div className="ac-field-list">
                    </div>
                    <div>{element.component}</div>
                    {this.renderAccordionInner(element.title)}
                  </div>
                </Collapse>
              </div>
            ))}
          </Col>
        </Row>
        {/*<Row>
          <Col xs={12} >
            <FormGroup className="form-action bg-widget-transparent">
              <Button onClick={this.handleUpdateDraft} className="mr-xs pull-right mid-button" color="primary" type="submit" disabled={!this.state.canUpdateDraft} >
                Update Report
              </Button>      
            </FormGroup>
          </Col>
        </Row>*/}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let model = state.buildings.curModel || {};
  let building = state.buildings.curBuilding.building || {};
  let pmodel = model.LPU || {};

  if (!pmodel?.report_override) {
    pmodel.report_override = {};
  }
  if (!pmodel?.report_override?.recommendations) {
    pmodel.report_override.recommendations = {};
  }

  return { model, building, isUpdating: state.buildings.isUpdating, pmodel };
}
export default withTranslation()(withRouter(connect(mapStateToProps)(ReportInfoPanel)));
