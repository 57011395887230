import i18next from "i18next";

const usersFields = {
    id: { type: 'id', label: 'id' },
    fullName: {
        type: 'string', label: 'fullName',

    },
    firstName: {
        type: 'string', label: 'firstName',

    },
    lastName: {
        type: 'string', label: 'lastName',

    },
    phoneNumber: {
        type: 'string', label: 'phoneNum',

    },
    oldPassword: {
        type: 'string', label: 'oldPass',

    },
    disabled: {
        type: 'boolean', label: 'disabled',

    },
    role: {
        type: 'enum', label: 'role',

        options: [

            { value: 'admin', label: 'admin' },
            { value: 'utilityadmin', label: 'utilityAdmin' },
            { value: 'remoteauditor', label: 'remoteAuditor' },
            { value: 'serviceorgmanager', label: 'serviceOrgManager' },
        ],

    },
    newPassword: {
        type: 'string', label: 'newPass',

    },
    confirmPassword: {
        type: 'string', label: 'confirmPass',

    },
    emailVerified: {
        type: 'boolean', label: 'emailVerified', ignore: true

    },
    emailVerificationToken: {
        type: 'string', label: 'emailVerificationToken', ignore: true

    },
    emailVerificationTokenExpiresAt: {
        type: 'datetime', label: 'emailVerificationTokenExpiresAt', ignore: true

    },
    passwordResetToken: {
        type: 'string', label: 'passwordResetToken', ignore: true

    },
    passwordResetTokenExpiresAt: {
        type: 'datetime', label: 'passwordResetTokenExpiresAt', ignore: true

    },

}

export default usersFields;
