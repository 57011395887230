/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import config from './config';

import './SimpPie.scss';
import { withTranslation } from 'react-i18next';

class SimpPie extends React.Component {

  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    data: PropTypes.array,
    isType2: PropTypes.bool
  };

  static defaultProps = {
    width: 268,
    height: 268,
    data: [],
    isType2: false
  };

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      total_type: window.HIDE_DOLLARS ? 1 : 0
    };
  }

  componentDidMount() {
    if (this.canvas !== this.canvasRef.current) {
      this.canvas = this.canvasRef.current;
      this.ctx = this.canvas.getContext('2d');
    }

    this.renderPieGraph();
  }

  componentDidUpdate(prevProps) {
    if (this.canvas !== this.canvasRef.current) {
      this.canvas = this.canvasRef.current;
      this.ctx = this.canvas.getContext('2d');
    }

    this.renderPieGraph();
  }

  renderPieGraph() {
    const data = !this.props.isType2 ? this.props.data : this.props.pdata;
    const width = this.props.width, height = this.props.height;

    // size canvas
    this.canvas.width = width;
    this.canvas.height = height;

    let ctx = this.ctx;

    // clear canvas
    ctx.clearRect(0, 0, width, height);

    // calc center, radii
    const cx = width * 0.5, cy = height * 0.5;
    const r_outer = width * 0.49, r_inner = width * 0.49 - 32;

    // sum values
    let totalVal = 0;
    for (let i=0; i<data.length; i++) {
      totalVal += data[i].val || 0;
    }

    // draw pie slices
    let curVal = 0;
    for (let i=0; i<data.length; i++) {
      let nextVal = curVal + (data[i].val || 0);

      ctx.beginPath();
      ctx.moveTo(cx, cy);
      ctx.arc(cx, cy, r_outer, (curVal / totalVal) * Math.PI * 2, (nextVal / totalVal) * Math.PI * 2);
      ctx.lineTo(cx, cy);
      ctx.fillStyle = data[i].color;
      ctx.fill();
      ctx.closePath();

      curVal = nextVal;
    }

    // clear center circle
    ctx.globalCompositeOperation = 'destination-out';
    ctx.beginPath();
    ctx.arc(cx, cy, r_inner, 0, Math.PI * 2);
    ctx.fillStyle = '#fff';
    ctx.fill();
    ctx.globalCompositeOperation = 'source-over';
  }

  toggleType() {
    this.setState({total_type: window.HIDE_DOLLARS ? 1 : (1-this.state.total_type)});
  }

  render() {

    const {t} = this.props;

    const data = !this.props.isType2 ? this.props.data : this.props.pdata;
    const total_dollars = !this.props.isType2 ? this.props.usage_dollars : this.props.pusage_dollars;
    const adjusted_flag = !this.props.isType2 ? this.props.adjusted_flag : this.props.padjusted_flag
    const no_bills_flag = !this.props.isType2 ? this.props.no_bills_flag : this.props.pno_bills_flag;
    const no_eh_flag = !this.props.isType2 ? this.props.no_eh_flag : this.props.pno_eh_flag;
    console.log({usage_dollars: this.props.usage_dollars})
    let totalVal = 0;
    for (let i=0; i<data.length; i++) {
      data[i].val = data[i].val || 0;
      totalVal += data[i].val;
    }

    let percents = [];
    let roundingError = 100;
    for (let i=0; i<data.length; i++) {
      let percent = Math.round(100*data[i].val / totalVal);
      roundingError -= percent;
      percents.push(percent);
    }

    while (roundingError) {
      for (let i=0; i<percents.length; i++) {
        if (roundingError > 0) {
          roundingError -= 1;
          percents[i] += 1;
        }
        else if (roundingError < 0) {
          roundingError += 1;
          percents[i] -= 1;
        }
      }
    }

    return (
      <div className='simp-pie-cont'>
        <div className='sp-canvas-cont'>
          <canvas ref={this.canvasRef}/>
          <div className='total-cont'>
            <div onClick={() => this.toggleType()} className='tc-tog'>
              <i className={`la la-angle-up`} />
            </div>
            <div className='tc-total'>{this.state.total_type === 0 ? `$${this.props.usage_dollars}` : `${Math.round(totalVal)} GJ`}</div>
            <div className='tc-per-year'>{t('perYear')}</div>
            <div onClick={() => this.toggleType()} className='tc-tog'>
              <i className={`la la-angle-down`} />
            </div>
          </div>
        </div>
        <div className='sh-label-outside'>
          <div className='sh-label-cont space-heating-label'>
            <div className='sh-box'/>
            <div className='sh-label'>
              <div className='sh-top-label'>{data[0].title}</div>
              <div className='sh-bot-label'>{Math.round(data[0].val)} {t('gjPerYear')} ({percents[0]}%)</div>
            </div>
          </div>
          <div className='sh-label-cont space-cooling-label'>
            <div className='sh-box'/>
            <div className='sh-label'>
              <div className='sh-top-label'>{data[1].title}</div>
              <div className='sh-bot-label'>{Math.round(data[1].val)} {t('gjPerYear')} ({percents[1]}%)</div>
            </div>
          </div>
          <div className='sh-label-cont lights-label'>
            <div className='sh-box'/>
            <div className='sh-label'>
              <div className='sh-top-label'>{data[2].title}</div>
              <div className='sh-bot-label'>{Math.round(data[2].val)} {t('gjPerYear')} ({percents[2]}%)</div>
            </div>
          </div>
          <div className='sh-label-cont water-label'>
            <div className='sh-box'/>
            <div className='sh-label'>
              <div className='sh-top-label'>{data[3].title}</div>
              <div className='sh-bot-label'>{Math.round(data[3].val)} {t('gjPerYear')} ({percents[3]}%)</div>
            </div>
          </div>
        </div>
        {false && no_bills_flag && <div className='sp-no-bill-msg'>* {t('msgModelUnableToMakeSufficientUtility')}</div>}
        {false && no_eh_flag && <div className='sp-no-bill-msg'>* {t('msgHomeLikelyNonElectricHeated')}</div>}
        {adjusted_flag && <div className='sp-adjusted-msg'>* {t('msgAdjustedUtilityData')}</div>}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const {t} = ownProps;
  let model = state.buildings.curModel || {};

  let adjusted_flag = (model?.model?.correction_factor || 1.) >= 0.6 && (model?.model?.correction_factor || 1.) <= 1.4;
  let no_bills_flag = !(model?.bills?.length);
  adjusted_flag = adjusted_flag && !no_bills_flag;
  let no_eh_flag = model?.model?.hdd_coef && !(model?.model?.hdd_coef < 0. && model?.metrics?.pvalue_hdd < 0.5);

  let electricallyHeated = model?.property?.primary_heating_fuel_type == 'Electricity' && (!model?.property?.secondary_heating_fuel_type || model?.property?.secondary_heating_fuel_type == 'Electricity');
  adjusted_flag = electricallyHeated && adjusted_flag;

  let breakdown = model.display_breakdown_gj || {};
  const base_breakdown = state?.buildings?.curModel?.__report_info?.bdListUnsorted || [];
  let data = [
    { color: "#4fbd6f", val: base_breakdown[0]?.usage, title: t('spaceHeating') },
    { color: "#44d9e6", val: base_breakdown[1]?.usage, title: t('spaceCooling') },
    { color: "#1a67c7", val: base_breakdown[3]?.usage, title: t('light&Appliances') },
    { color: "#d65229", val: base_breakdown[2]?.usage, title: t('hotWater') },
  ];

  let pmodel = state.buildings.curModel?.LPRE || {};
  let padjusted_flag = (pmodel?.model?.correction_factor || 1.) >= 0.6 && (pmodel?.model?.correction_factor || 1.) <= 1.4;
  let pno_bills_flag = !(pmodel?.bills?.length);
  padjusted_flag = padjusted_flag && !pno_bills_flag;
  let pno_eh_flag = !padjusted_flag;

  let pElectricallyHeated = pmodel?.property?.primary_heating_fuel_type == 'Electricity' && (!pmodel?.property?.secondary_heating_fuel_type || pmodel?.property?.secondary_heating_fuel_type == 'Electricity');
  padjusted_flag = pElectricallyHeated && padjusted_flag;

  let pbreakdown = state?.buildings?.curModel?.LPRE?.__report_info?.bdListUnsorted || [];
  let pdata = [
    { color: "#4fbd6f", val: pbreakdown[0]?.usage, title: t('spaceHeating') },
    { color: "#44d9e6", val: pbreakdown[1]?.usage, title: t('spaceCooling') },
    { color: "#1a67c7", val: pbreakdown[3]?.usage, title: t('light&Appliances') },
    { color: "#d65229", val: pbreakdown[2]?.usage, title: t('hotWater') },
  ];

  if (pno_eh_flag) {
    padjusted_flag = false;
  }
  if (no_eh_flag) {
    adjusted_flag = false;
  }

  return { data: data, pdata: pdata, usage_dollars: Math.round((state?.buildings?.curModel?.LPRE?.__report_info?.bundle_recommendations?.baseline?.graphs?.baseline_energy_bill_cost_graph || [])[0]), pusage_dollars: Math.round((state?.buildings?.curModel?.LPRE?.__report_info?.bundle_recommendations?.baseline?.graphs?.baseline_energy_bill_cost_graph || [])[0]), adjusted_flag, padjusted_flag, no_bills_flag, pno_bills_flag, no_eh_flag, pno_eh_flag };
}
export default withTranslation()(withRouter(connect(mapStateToProps)(SimpPie)));
